import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonType } from '../../../../../../../shared/store/subheader/type';
import { ModalContentComponent } from '../../../../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../../../../shared/components/modal-content/action-button';
import { SourceEnum } from '../../../../../../../shared/store/typeahead/types';
import { ModalService } from '../../../../../../../shared/service/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesObjectivesRuleModel } from '../../../../../../../shared/store/sales-objectives-rule/types';
import { Observable } from 'rxjs';
import { SalesObjectivesRuleService } from '../../../../../../../shared/store/sales-objectives-rule/sales-objectives-rule.service';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubHeaderService } from '../../../../../../../shared/store/subheader/subheader.service';
import { filterTrue } from '../../../../../../../shared/pipe/rxjs/operators';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-user-details-sales-objectives-rules',
  templateUrl: './user-details-sales-objectives-rules.component.html',
  styleUrl: './user-details-sales-objectives-rules.component.scss',
})
export class UserDetailsSalesObjectivesRulesComponent
  implements OnInit, OnDestroy
{
  userId: number;
  salesObjectivesRules$: Observable<SalesObjectivesRuleModel[]>;

  rowData: SalesObjectivesRuleModel;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly salesObjectivesRuleService: SalesObjectivesRuleService,
    private readonly route: ActivatedRoute,
    private readonly modalExchangeService: ModalService,
    private readonly modalService: NgbModal,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.salesObjectivesRules$ =
      this.salesObjectivesRuleService.getByUserId.value$;
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params['id'];
    this.salesObjectivesRuleService.getByUserId.call(this.userId.toString());

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'salesObjectivesRuleEdit') {
          this.onClickOpenEditModal();
        }
        if (x == 'salesObjectivesRuleDelete') {
          this.onDelete();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy(): void {
    this.salesObjectivesRuleService.getByUserId.reset();
  }

  onGetRowData() {
    //copy the data
    const result = this.rowData;

    //when we get the data, reset the data
    this.onSetRowData(undefined);

    //return the copy of the data
    return result;
  }

  onSetRowData(rowData: any) {
    this.rowData = rowData;
  }

  onDelete() {
    const salesObjectivesRuleModel: SalesObjectivesRuleModel =
      this.onGetRowData();

    let result$ = this.salesObjectivesRuleService.delete.call(
      salesObjectivesRuleModel.id.toString(),
    );

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.salesObjectivesRuleService.delete.reset();
      this.salesObjectivesRuleService.getByUserId.call(this.userId.toString());
    });
  }

  onClickOpenEditModal() {
    const salesObjectivesRuleModel: SalesObjectivesRuleModel =
      this.onGetRowData();

    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'salesobjectivesruleformcontentcomponent',
      );

    modalRef.componentInstance.title = 'USER_LBL_SALES_OBJECTIVES_RULES_ADD';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'salesObjectivesRuleUpdate',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      userId: this.userId,
      inputSalesObjectivesRuleId: salesObjectivesRuleModel?.id,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.salesObjectivesRuleService.getByUserId.call(
            this.userId.toString(),
          );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }
}
