import { createApiCallActions } from '../../../common/store/generic.actions';
import {
  AccountModel,
  AccountMoveModel,
  AccountMovePeriodModel,
  CashJournalEntry,
  CashJournalModel,
  JournalAssignRecord,
  SlipResponseModel,
} from './types';

export const accountingActions = {
  getById: createApiCallActions<AccountMoveModel>('accounting', 'getById'),
  getByContactId: createApiCallActions<AccountMoveModel[]>(
    'accounting',
    'getByContactId'
  ),
  getByJournalId: createApiCallActions<AccountMoveModel[]>(
    'accounting',
    'getByJournalId'
  ),
  getUnbindedByContactId: createApiCallActions<AccountMoveModel[]>(
    'accounting',
    'getUnbindedByContactId'
  ),
  getUnbindedByContractId: createApiCallActions<AccountMoveModel[]>(
    'accounting',
    'getUnbindedByContractId'
  ),
  createInstance: createApiCallActions<AccountMoveModel>(
    'accounting',
    'createInstance'
  ),
  add: createApiCallActions<AccountMoveModel>('accounting', 'add'),
  bind: createApiCallActions<boolean>('accounting', 'bind'),
  getPeriods: createApiCallActions<AccountMovePeriodModel[]>(
    'accounting',
    'getPeriods'
  ),
  importSlip: createApiCallActions<SlipResponseModel[]>(
    'accounting',
    'importSlip'
  ),
  changeAccountMoveStatus: createApiCallActions<AccountMoveModel>(
    'accounting',
    'changeAccountMoveStatus'
  ),
  getUnbindedRepayment: createApiCallActions<AccountMoveModel[]>(
    'accounting',
    'getUnbindedRepayment'
  ),
  uploadProof: createApiCallActions<boolean>('accounting', 'uploadProof'),
  getUnbindedSlip: createApiCallActions<JournalAssignRecord[]>(
    'accounting',
    'getUnbindedSlip'
  ),
  getAccountMovePdf: createApiCallActions<any>(
    'accounting',
    'getAccountMovePdf'
  ),
  getBindedAccountMoveByAccountMoveId: createApiCallActions<any>(
    'accounting',
    'getBindedAccountMoveByAccountMoveId'
  ),
  delete: createApiCallActions<boolean>('accounting', 'delete'),
  unbind: createApiCallActions<boolean>('accounting', 'unbind'),
  getAccountByContactId: createApiCallActions<AccountModel>(
    'accounting',
    'getAccountByContactId'
  ),
  getAccountById: createApiCallActions<AccountModel>(
    'accounting',
    'getAccountById'
  ),
  getCashJournal: createApiCallActions<CashJournalModel>(
    'accounting',
    'getCashJournal'
  ),
};

export type AccountingAction = typeof accountingActions;
