import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { SalesObjectivesRuleApiService } from './sales-objectives-rule.api.service';
import {
  SalesObjectivesRuleAction,
  salesObjectivesRuleActions,
} from './sales-objectives-rule.actions';

@Injectable()
export class SalesObjectivesRuleEffects {
  constructor(
    private readonly salesObjectivesRuleApiService: SalesObjectivesRuleApiService,
    private readonly action$: Actions<SalesObjectivesRuleAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    salesObjectivesRuleActions.getById,
    this.salesObjectivesRuleApiService.getById,
  );
  getByUserId = createApiCallEffects(
    this,
    this.action$,
    salesObjectivesRuleActions.getByUserId,
    this.salesObjectivesRuleApiService.getByUserId,
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    salesObjectivesRuleActions.createInstance,
    this.salesObjectivesRuleApiService.createInstance,
  );
  add = createApiCallEffects(
    this,
    this.action$,
    salesObjectivesRuleActions.add,
    this.salesObjectivesRuleApiService.add,
  );
  update = createApiCallEffects(
    this,
    this.action$,
    salesObjectivesRuleActions.update,
    this.salesObjectivesRuleApiService.update,
  );
  delete = createApiCallEffects(
    this,
    this.action$,
    salesObjectivesRuleActions.delete,
    this.salesObjectivesRuleApiService.delete,
  );
}
