import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Domain, Filter } from '../../store/search/types';
import { Observable } from 'rxjs';
import { SearchService } from '../../store/search/search.service';
import { isValidDate } from '../../helper/datehelper';

@Component({
  selector: 'app-search-bar-badge',
  templateUrl: './search-bar-badge.component.html',
  styleUrls: ['./search-bar-badge.component.scss'],
})
export class SearchBarBadgeComponent implements OnInit {
  activeFilters$: Observable<Filter[]>;
  formattedDomains: Domain[];

  protected readonly isValidDate = isValidDate;

  constructor(private readonly searchService: SearchService) {
    this.activeFilters$ = this.searchService.getActiveFilters();
  }

  ngOnInit(): void {}
  removeFilter(filterIndex: number) {
    this.searchService.removeActiveFilterByIndex(filterIndex);
  }
  isAtLeastOneDomainActive(filter: Filter) {
    let result: boolean = false;
    filter.domains.forEach((domain) => {
      if (domain.isActive) {
        result = true;
      }
    });
    return result;
  }

  trackByName(index: any, item: { name: string }) {
    return item.name;
  }

  trackByIndex(index: any) {
    return index;
  }
}
