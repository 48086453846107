import { createApiCallActions } from '../../../common/store/generic.actions';
import { SalesTeamMember } from '../sales-team-member/types';
import { SalesTeamModel } from './types';
import { ListItem } from '../typeahead/types';

export const salesTeamActions = {
  getById: createApiCallActions<SalesTeamModel>('salesTeam', 'getById'),
  getList: createApiCallActions<ListItem[]>('salesTeam', 'getList'),
  getCompanyCommissionList: createApiCallActions<ListItem[]>(
    'salesTeam',
    'getCompanyCommissionList'
  ),
  getSalesTeamMemberList: createApiCallActions<SalesTeamMember[]>(
    'salesTeam',
    'getSalesTeamMemberList'
  ),
  getCompanyCommissionSalesTeamMemberList: createApiCallActions<
    SalesTeamMember[]
  >('salesTeam', 'getCompanyCommissionSalesTeamMemberList'),
  create: createApiCallActions<SalesTeamModel>('salesTeam', 'create'),
  postSalesTeam: createApiCallActions<SalesTeamModel>(
    'salesTeam',
    'postSalesTeam'
  ),
  updateSalesTeam: createApiCallActions<SalesTeamModel>(
    'salesTeam',
    'updateSalesTeam'
  ),
};

export type SalesTeamAction = typeof salesTeamActions;
