<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="changePasswordForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">
    <div class="col-lg-12 col-12">

      <div class="form-group row">
        <label class="col col-form-label-sm" translate>
          ACCOUNT_LBL_USERNAME
        </label>
        <input
          class="form-control-plaintext form-control-md"
          type="text"
          readonly
          [value]="this.userEmail"
        />
      </div>
      <div class="form-group row">
        <label class="col col-form-label-sm" for="current" translate>
          ACCOUNT_LBL_CURRENT_PASSWORD</label>
        <div class="input-group ">
          <input
            class="form-control form-control-md"
            id="current"
            name="current"
            type="password"
            value=""
            formControlName="current"
            checkFormFieldValidity
            autocomplete="new-password"
          />

        </div>
      </div>


      <div class="form-group row">
        <label class="col col-form-label-sm" for="password" translate>
          ACCOUNT_LBL_NEW_PASSWORD</label>
        <div class="input-group position-relative">
          <input
            #password
            class="form-control form-control-md input-eye"
            id="password"
            name="password"
            type="password"
            value=""
            formControlName="password"
            checkFormFieldValidity
          />
          <span class="input-group-text position-absolute end-0 eye"
                (click)="password.type = password.type === 'password' ? 'text' : 'password'"
                role="button" tabindex="0">
              <i [class]="password.type === 'password' ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"></i>
          </span>
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col">
          <ul class="no-bullets">
            <li>Doit avoir au moins de 6 caractères</li>
            <li>Doit contenir au moins une majuscule et une minuscule (A, z)</li>
            <li>Doit contenir au moins un nombre</li>
            <li>Doit contenir au moins un caractère spécial (!, %, &#64;, #, etc.)</li>
          </ul>
        </div>
      </div>
      <div class="form-group row">
        <label class="col col-form-label-sm" for="passwordConfirm" translate>
          ACCOUNT_LBL_CONFIRM_PASSWORD</label>
        <div class="input-group position-relative">
          <input
            #password2
            class="form-control form-control-md input-eye"
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            value=""
            formControlName="passwordConfirm"
            checkFormFieldValidity
          />
          <span class="input-group-text position-absolute end-0 eye"
                (click)="password2.type = password2.type === 'password' ? 'text' : 'password'"
                role="button" tabindex="0">
              <i [class]="password2.type === 'password' ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"></i>
          </span>
        </div>
      </div>
    </div>
  </div>

</form>
