import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ContractModel } from '../../../../../../shared/store/contract/types';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { DocumentModel } from '../../../../../../shared/store/document/types';
import { DocumentService } from '../../../../../../shared/store/document/document.service';
import { filter, map, take } from 'rxjs/operators';
import {
  debugLog,
  filterTrue,
} from '../../../../../../shared/pipe/rxjs/operators';
import { SubHeaderService } from '../../../../../../shared/store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalContentComponent } from '../../../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../../../shared/components/modal-content/action-button';
import { toFirstLetterLower } from '../../../../../../shared/helper/stringhelper';
import { SourceEnum } from '../../../../../../shared/store/typeahead/types';
import { ConfigurationService } from '../../../../../../shared/service/configuration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../../../shared/service/modal.service';
import { FileSaverService } from 'ngx-filesaver';

@UntilDestroy()
@Component({
  selector: 'app-contract-details-documents',
  templateUrl: './contract-details-documents.component.html',
  styleUrl: './contract-details-documents.component.scss',
})
export class ContractDetailsDocumentsComponent implements OnInit, OnDestroy {
  @Input({ required: true }) contactId: number;
  @Input({ required: true }) contractId: number;
  @Input({ required: true }) contractUniqueId: string;

  contractDetail$: Observable<ContractModel>;

  sortedDocuments$: Observable<DocumentModel[]>;

  contactDocuments$: Observable<DocumentModel[]>;
  contractDocuments$: Observable<DocumentModel[]>;
  allDocuments$: Observable<DocumentModel[]>;

  copyToDocument$: Observable<DocumentModel>;

  rowData: DocumentModel;
  downloadClicked: boolean = false;

  constructor(
    private readonly contractService: ContractService,
    private readonly documentService: DocumentService,
    private readonly subHeaderService: SubHeaderService,
    private readonly configurationService: ConfigurationService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly fileSaverService: FileSaverService,
  ) {
    this.contractDetail$ = this.contractService.getByUniqueId.value$;

    this.contactDocuments$ =
      this.documentService.getContactDocumentByEntityId.value$;
    this.contractDocuments$ =
      this.documentService.getContractDocumentByEntityId.value$;
    this.copyToDocument$ = this.documentService.copyToDocumentRequest.value$;
  }

  ngOnInit(): void {
    this.documentService.getContactDocumentByEntityId.call({
      entityId: this.contactId,
      entityType: 'Contact',
    });
    this.documentService.getContractDocumentByEntityId.call({
      entityId: this.contractId,
      entityType: 'Contract',
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'documentDownload') {
          this.onClickDownload();
        }
        if (x == 'documentDelete') {
          this.onDelete();
        }
        if (x == 'documentEdit') {
          this.onOpenModalEdit();
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.sortedDocuments$ = this.contractDetail$.pipe(
      map((contractModel) => {
        return (
          [...contractModel.documents]
            //Only take the request
            .filter((x) => !!x.requestDate)
            //Sort by mandatory and then by document type name
            .sort((a, b) => {
              if (
                a.productCategoryDocumentTypeMandatory ===
                b.productCategoryDocumentTypeMandatory
              ) {
                if (a.documentTypeName < b.documentTypeName) {
                  return -1;
                }

                if (a.documentTypeName > b.documentTypeName) {
                  return 1;
                }
                return 0;
              }

              if (a.productCategoryDocumentTypeMandatory) {
                return -1;
              }

              if (b.productCategoryDocumentTypeMandatory) {
                return 1;
              }

              return 1;
            })
        );
      }),
    );

    this.allDocuments$ = combineLatest([
      this.contactDocuments$,
      this.contractDocuments$,
    ]).pipe(
      map(([contactDocumentModels, contractDocumentModels]) => {
        let allDocumentModels: DocumentModel[] = [];

        if (contactDocumentModels && contactDocumentModels.length > 0) {
          allDocumentModels.push(...contactDocumentModels);
        }
        if (contractDocumentModels && contractDocumentModels.length > 0) {
          allDocumentModels.push(
            ...contractDocumentModels.filter((x) => !x.requestDate),
          );
        }

        return allDocumentModels;
      }),
    );

    this.copyToDocument$.pipe(filterTrue()).subscribe((document) => {
      if (document) {
        this.onRefreshContractDocument(true);
        this.onRefreshContactDocument(true);
        this.documentService.copyToDocumentRequest.reset();
      }
    });
  }

  onNewDocument(success: boolean) {
    if (success) {
      this.contractService.getByUniqueId.call(this.contractUniqueId);
    }
  }

  onRefreshContactDocument(refresh: boolean) {
    if (refresh) {
      this.documentService.getContactDocumentByEntityId.call({
        entityType: 'Contact',
        entityId: this.contactId,
      });
    }
  }

  onRefreshContractDocument(refresh: boolean) {
    if (refresh) {
      this.documentService.getContractDocumentByEntityId.call({
        entityType: 'Contract',
        entityId: this.contractId,
      });
    }
  }

  onGetRowData() {
    //copy the data
    const result = this.rowData;

    //when we get the data, reset the data
    this.onSetRowData(undefined);

    //return the copy of the data
    return result;
  }

  onSetRowData(rowData: any) {
    this.rowData = rowData;
  }

  private onClickDownload(documentModel?: DocumentModel) {
    this.downloadClicked = true;
    const document: DocumentModel = documentModel ?? this.onGetRowData();

    const getFileSubscription = this.documentService.getFile.value$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result: Blob) => {
        if (
          this.configurationService
            .getOpenFileExtensions()
            .some((x) => x.includes(document.fileExtension))
        ) {
          const fileURL = window.URL.createObjectURL(result);
          let tab = window.open();
          if (tab) {
            tab.location.href = fileURL;
          }
        } else {
          this.fileSaverService.save(
            result,
            document.name,
            document.contentType,
          );
        }
        this.downloadClicked = false;
        // this.downloadFinished = true;
        this.documentService.getFile.reset();
      });

    this.documentService.getFile.call(document.id.toString());
  }

  private onDelete(documentModel?: DocumentModel) {
    const document: DocumentModel = documentModel ?? this.onGetRowData();

    // this.confirmationDialogService
    //   .confirm('Confirmation', 'Voulez-vous supprimer ?')
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       let result$ = this.documentService.delete.call(
    //         document.id.toString(),
    //       );
    //
    //       result$.pipe(filterTrue(), take(1)).subscribe((result) => {
    //         this.documentService.delete.reset();
    //       });
    //     }
    //   })
    //   .catch(() => {});

    let result$ = this.documentService.delete.call(document.id.toString());

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      //refresh data
      switch (document.entityType) {
        case 'Contact':
          this.onRefreshContactDocument(true);
          break;
        case 'Contract':
          this.onRefreshContractDocument(true);
          break;
      }
      this.documentService.delete.reset();
    });
  }

  private onOpenModalEdit(documentModel?: DocumentModel) {
    if (this.downloadClicked) {
      this.downloadClicked = false;
      return;
    }
    const document: DocumentModel = documentModel ?? this.onGetRowData();

    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'DocumentEditContentComponent',
      );
    modalRef.componentInstance.title = 'Document';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('document') + 'Update',
      },
    ];
    modalRef.componentInstance.inputParams = {
      modalId: document.id,
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          modalRef.close();

          //refresh data
          switch (document.entityType) {
            case 'Contact':
              this.onRefreshContactDocument(true);
              break;
            case 'Contract':
              if (document.requestDate && document.fileExtension) {
                this.contractService.getByUniqueId.call(this.contractUniqueId);
              } else {
                this.onRefreshContractDocument(true);
              }
              break;
          }
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  onDocumentDownload(document: DocumentModel) {
    this.onClickDownload(document);
  }
  onDocumentEdit(document: DocumentModel) {
    this.onOpenModalEdit(document);
  }
  onDocumentDelete(document: DocumentModel) {
    let result$ = this.documentService.deleteFile.call(document.id.toString());

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      //refresh data
      this.contractService.getByUniqueId.call(this.contractUniqueId);

      this.documentService.deleteFile.reset();
    });
  }

  ngOnDestroy(): void {
    this.documentService.deleteFile.reset();
    this.documentService.delete.reset();
  }
}
