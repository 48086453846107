import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PageResult } from '../../types';
import { User, UserConnectionHistory } from './type';

export const userActions = {
  get: createApiCallActions<User[]>('user', 'get'),
  getById: createApiCallActions<User>('user', 'getById'),
  add: createApiCallActions<User>('user', 'add'),
  update: createApiCallActions<User>('user', 'update'),
  lockout: createApiCallActions<boolean>('user', 'lockout'),
  getPaged: createApiCallActions<PageResult<User>>('user', 'getPaged'),
  getCurrentUser: createApiCallActions<User>('currentUser', 'getCurrentUser'),
  getConnectionHistoriesById: createApiCallActions<UserConnectionHistory[]>(
    'user',
    'getConnectionHistoriesById',
  ),
  getMainSalesTeamById: createApiCallActions<number | undefined>(
    'user',
    'getMainSalesTeamById',
  ),
};
export type UserAction = typeof userActions;
