<app-document-subheader/>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.salesObjectivesRuleModel$ | async as salesObjectivesRuleModel">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row">

                <div class="col-6 col-lg-6">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>SALES_OBJECTIVES_RULE_LBL_DAILY_AMOUNT</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ salesObjectivesRuleModel.dailyAmount | showSignValueType: this.defaultAmountType }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>SALES_OBJECTIVES_RULE_LBL_MONTHLY_AMOUNT</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ salesObjectivesRuleModel.monthlyAmount | showSignValueType: this.defaultAmountType }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>SALES_OBJECTIVES_RULE_LBL_YEARLY_AMOUNT</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ salesObjectivesRuleModel.yearlyAmount | showSignValueType: this.defaultAmountType }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>SALES_OBJECTIVES_RULE_LBL_START_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{
                          !!salesObjectivesRuleModel.startDate
                            ? (salesObjectivesRuleModel.startDate | date : 'dd/MM/yyyy')
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>SALES_OBJECTIVES_RULE_LBL_END_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{
                          !!salesObjectivesRuleModel.endDate
                            ? (salesObjectivesRuleModel.endDate | date : 'dd/MM/yyyy')
                            : '-'
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.salesObjectivesRuleModel$ | async as salesObjectivesRuleModel">
      <app-comment-panel [entityType]="'SalesObjectivesRule'"
                         [entityId]="salesObjectivesRuleModel.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
