import { DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

export function formatDate(
  date: Date | null,
  type:
    | 'ToShortTimeString'
    | 'ToLongDay'
    | 'ToSqlDate'
    | 'ToInverseSqlDate'
    | 'ToFriendlyDate'
    | 'Autre',
): string {
  if (!date) return '';

  registerLocaleData(localeFr, 'fr-FR');
  const locale: string = 'fr-FR';

  let result: string | null;

  switch (type) {
    case 'ToShortTimeString':
      result = new DatePipe(locale).transform(date, 'HH:mm');
      break;
    case 'ToSqlDate':
      result = new DatePipe(locale).transform(date, 'dd-MM-yyyy');
      break;
    case 'ToInverseSqlDate':
      result = new DatePipe(locale).transform(date, 'yyyy-MM-dd');
      break;
    case 'ToLongDay':
      result = new DatePipe(locale).transform(date, 'EEEE');
      break;
    case 'ToFriendlyDate':
      result = new DatePipe(locale).transform(date, 'EEE dd LLLL');
      break;
    default:
      result = '';
      break;
  }

  return result === null ? '' : result;
}

//https://stackoverflow.com/questions/542938/how-to-calculate-number-of-days-between-two-dates
export function dateDiff(str1, str2) {
  var diff = Date.parse(str2) - Date.parse(str1);
  return isNaN(diff)
    ? {
        diff: 0,
        millisec: 0,
        sec: 0,
        minute: 0,
        hour: 0,
        day: 0,
      }
    : {
        diff: diff,
        millisec: Math.round(diff % 1000),
        sec: Math.round((diff / 1000) % 60),
        minute: Math.round((diff / 60000) % 60),
        hour: Math.round((diff / 3600000) % 24),
        day: Math.round(diff / 86400000),
      };
}

export function dateAndTimeToDate(
  date: Date,
  time: string,
  timeSeparator: string = ':',
) {
  const toDate: Date = new Date(date);
  const toArrayTime = time.split(timeSeparator);

  let toTime: Date = new Date();
  if (
    toArrayTime.length == 3 &&
    Number.isInteger(toArrayTime[0]) &&
    Number.isInteger(toArrayTime[1]) &&
    Number.isInteger(toArrayTime[2])
  ) {
    toTime = new Date(
      1,
      1,
      1,
      +toArrayTime[0],
      +toArrayTime[1],
      +toArrayTime[2],
    );
  }
  if (
    toArrayTime.length == 2 &&
    Number.isInteger(toArrayTime[0]) &&
    Number.isInteger(toArrayTime[1])
  ) {
    toTime = new Date(1, 1, 1, +toArrayTime[0], +toArrayTime[1]);
  }

  return new Date(
    toDate.getFullYear(),
    toDate.getMonth(),
    toDate.getDate(),
    toTime.getHours(),
    toTime.getMinutes(),
    toTime.getSeconds(),
  );
}

export function isValidDate(dateString: string): boolean {
  //is number
  if (!isNaN(+dateString)) return false;

  //is date
  const date = Date.parse(dateString);
  return !isNaN(date);
}
