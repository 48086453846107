<app-company-commission-subheader />
<div>
  <div class="details-content container-fluid h-100">
    <div class="row h-100">
      <div class="col-content d-flex flex-column h-100">
        <div class="page-container">
          <div class="card card-light w-100">
            <div class="card-body">
              <div class="container-fluid">
                <div
                  class="row mt-2"
                  *ngIf="companyCommissions$ | async as companyCommissions"
                >
                  <div class="form-group-title my-1" translate>
                    COMPANY_COMMISSION_FIELDSET_COMPANY_COMMISSION_GRID_NAME
                  </div>
                  <div class="col-12 col-md">
                    <app-generic-grid
                      style="height: 300px"
                      [data]="companyCommissions"
                      [tabViewName]="'companyCommissions'"
                    />
                  </div>
                </div>
                <div
                  class="row mt-2"
                  *ngIf="
                    applicationFeesReport$ | async as applicationFeesReport
                  "
                >
                  <div class="form-group-title my-1" translate>
                    COMPANY_COMMISSION_FIELDSET_APPLICATION_FEES_GRID_NAME
                  </div>
                  <div class="col-12 col-md">
                    <app-generic-grid
                      style="height: 300px"
                      [data]="applicationFeesReport"
                      [tabViewName]="'companyCommissions'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
