export const salesObjectivesRuleFeatureKey = 'salesObjectivesRule';

export class SalesObjectivesRuleModel {
  id: number;
  name: string;
  userId?: number;
  dailyAmount: number;
  monthlyAmount: number;
  yearlyAmount: number;
  startDate: Date;
  endDate?: Date;
}
