<div
  class="tab-pane fade flex-even active show h-100"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.salesObjectivesRules$ | async as salesObjectivesRuleModels"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <button
          type="button"
          [class]="ButtonType.Primary | buttontypepipe"
          class="btn-sm btn"
          translate
          (click)="onClickOpenEditModal()"
        >
          USER_LBL_SALES_OBJECTIVES_RULES_ADD
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md">
        <app-generic-grid
          style="height: 300px"
          [data]="salesObjectivesRuleModels"
          [tabViewName]="'salesObjectivesRules'"
          (onActionClickedSetRowData)="onSetRowData($event)"
        />
      </div>
    </div>
  </div>
</div>
