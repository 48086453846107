import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { salesObjectivesRuleActions } from './sales-objectives-rule.actions';
import { salesObjectivesRuleSelectors } from './sales-objectives-rule.selector';
import { AppState } from '../types';
import { SalesObjectivesRuleModel } from './types';

@Injectable()
export class SalesObjectivesRuleService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<SalesObjectivesRuleModel, string> =
    this.genericApiCall(
      salesObjectivesRuleActions.getById,
      salesObjectivesRuleSelectors.getById,
      true,
    );
  getByUserId: GenericApiCall<SalesObjectivesRuleModel[], string | undefined> =
    this.genericApiCall(
      salesObjectivesRuleActions.getByUserId,
      salesObjectivesRuleSelectors.getByUserId,
      true,
    );
  createInstance: GenericApiCall<
    SalesObjectivesRuleModel,
    { salesObjectivesUserId: number | undefined; startDate: Date | undefined }
  > = this.genericApiCall(
    salesObjectivesRuleActions.createInstance,
    salesObjectivesRuleSelectors.createInstance,
    true,
  );
  add: GenericApiCall<SalesObjectivesRuleModel, SalesObjectivesRuleModel> =
    this.genericApiCall(
      salesObjectivesRuleActions.add,
      salesObjectivesRuleSelectors.add,
      true,
    );
  update: GenericApiCall<SalesObjectivesRuleModel, SalesObjectivesRuleModel> =
    this.genericApiCall(
      salesObjectivesRuleActions.update,
      salesObjectivesRuleSelectors.update,
      true,
    );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    salesObjectivesRuleActions.delete,
    salesObjectivesRuleSelectors.delete,
    true,
  );
}
