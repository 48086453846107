import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { ContractAction, contractActions } from './contract.actions';
import { ContractApiService } from './contract.api.services';
import { IContractState } from './contract.reducer';
import { documentActions } from '../document/document.actions';
import { commentActions } from '../comment/comment.action';
import { contractSelectors } from './contract.selectors';

@Injectable()
export class ContractEffects {
  constructor(
    private readonly store: Store<IContractState>,
    private readonly contractApiService: ContractApiService,
    private readonly actions$: Actions<ContractAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getById,
    this.contractApiService.getById,
  );

  getByUniqueId = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getByUniqueId,
    this.contractApiService.getByUniqueId,
    () => {},
    () => {
      return this.store.select(contractSelectors.getByUniqueId.paramsSelector);
    },
  );

  getByLeadId = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getByLeadId,
    this.contractApiService.getByLeadId,
  );

  getHealthDetail = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getHealthDetail,
    this.contractApiService.getHealthDetail,
  );

  getCarDetail = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getCarDetail,
    this.contractApiService.getCarDetail,
  );

  getHouseDetail = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getHouseDetail,
    this.contractApiService.getHouseDetail,
  );

  getStatusFlow = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getStatusFlow,
    this.contractApiService.getStatusFlow,
  );

  confirm = createApiCallEffects(
    this,
    this.actions$,
    contractActions.confirm,
    this.contractApiService.confirm,
    (value) => {
      this.store.dispatch(
        contractActions.getByUniqueId.addedOrUpdated({ value }),
      );
      this.store.dispatch(documentActions.getByEntityId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  saveAsDraft = createApiCallEffects(
    this,
    this.actions$,
    contractActions.saveAsDraft,
    this.contractApiService.saveAsDraft,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  requestValidation = createApiCallEffects(
    this,
    this.actions$,
    contractActions.requestValidation,
    this.contractApiService.requestValidation,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  validate = createApiCallEffects(
    this,
    this.actions$,
    contractActions.validate,
    this.contractApiService.validate,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  reject = createApiCallEffects(
    this,
    this.actions$,
    contractActions.reject,
    this.contractApiService.reject,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  markAsRegularized = createApiCallEffects(
    this,
    this.actions$,
    contractActions.markAsRegularized,
    this.contractApiService.markAsRegularized,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  verify = createApiCallEffects(
    this,
    this.actions$,
    contractActions.verify,
    this.contractApiService.verify,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  suspendContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.suspendContract,
    this.contractApiService.suspendContract,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );
  closeContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.closeContract,
    this.contractApiService.closeContract,
    (value) => {
      this.store.dispatch(contractActions.getByUniqueId.refresh());
      this.store.dispatch(commentActions.getByEntityId.refresh());
    },
  );

  getStatusActions = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getStatusActions,
    this.contractApiService.getStatusActions,
  );

  getContractModel = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getContractModel,
    this.contractApiService.getContractModel,
  );
  getHealthContractModel = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getHealthContractModel,
    this.contractApiService.getHealthContractModel,
  );
  getCarContractModel = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getCarContractModel,
    this.contractApiService.getCarContractModel,
  );
  getHouseContractModel = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getHouseContractModel,
    this.contractApiService.getHouseContractModel,
  );

  createContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.createContract,
    this.contractApiService.createContract,
  );

  createHealthContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.createHealthContract,
    this.contractApiService.createHealthContract,
  );
  createCarContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.createCarContract,
    this.contractApiService.createCarContract,
  );
  createHouseContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.createHouseContract,
    this.contractApiService.createHouseContract,
  );

  updateContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.updateContract,
    this.contractApiService.updateContract,
  );

  updateHealthContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.updateHealthContract,
    this.contractApiService.updateHealthContract,
  );
  updateCarContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.updateCarContract,
    this.contractApiService.updateCarContract,
  );
  updateHouseContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.updateHouseContract,
    this.contractApiService.updateHouseContract,
  );

  getIfDataReadOnlyFromContractStatus = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getIfDataReadOnlyFromContractStatus,
    this.contractApiService.getIfDataReadOnlyFromContractStatus,
  );
}
