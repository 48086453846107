<form [formGroup]="salesObjectivesRuleForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="salesObjectivesRuleForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">

    <div class="col-6 col-lg-6">

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="dailyAmount" translate>SALES_OBJECTIVES_RULE_LBL_DAILY_AMOUNT</label>
        <div class="col">
          <input
            id="dailyAmount"
            name="dailyAmount"
            class="form-control form-control-sm"
            type="text"
            formControlName="dailyAmount"
            checkFormFieldValidity
            [placeholder]="this.amountPlaceholder | translate"
            mask="separator.2"
            [suffix]="this.amountSuffix | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="false"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="monthlyAmount" translate>SALES_OBJECTIVES_RULE_LBL_MONTHLY_AMOUNT</label>
        <div class="col">
          <input
            id="monthlyAmount"
            name="monthlyAmount"
            class="form-control form-control-sm"
            type="text"
            formControlName="monthlyAmount"
            checkFormFieldValidity
            [placeholder]="this.amountPlaceholder | translate"
            mask="separator.2"
            [suffix]="this.amountSuffix | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="false"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="yearlyAmount" translate>SALES_OBJECTIVES_RULE_LBL_YEARLY_AMOUNT</label>
        <div class="col">
          <input
            id="yearlyAmount"
            name="yearlyAmount"
            class="form-control form-control-sm"
            type="text"
            formControlName="yearlyAmount"
            checkFormFieldValidity
            [placeholder]="this.amountPlaceholder | translate"
            mask="separator.2"
            [suffix]="this.amountSuffix | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="false"
          />
        </div>
      </div>

    </div>

    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="startDate" translate>SALES_OBJECTIVES_RULE_LBL_START_DATE</label>
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="startDate"
            type="date"
            formControlName="startDate"
            checkFormFieldValidity
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="endDate" translate>SALES_OBJECTIVES_RULE_LBL_END_DATE</label>
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="endDate"
            type="date"
            formControlName="endDate"
            checkFormFieldValidity
          />
        </div>
      </div>
    </div>
  </div>
</form>
