import { createApiCallActions } from '../../../common/store/generic.actions';
import { SalesObjectivesRuleModel } from './types';

export const salesObjectivesRuleActions = {
  getById: createApiCallActions<SalesObjectivesRuleModel>(
    'salesObjectivesRule',
    'getById',
  ),
  getByUserId: createApiCallActions<SalesObjectivesRuleModel[]>(
    'salesObjectivesRule',
    'getByUserId',
  ),
  createInstance: createApiCallActions<SalesObjectivesRuleModel>(
    'salesObjectivesRule',
    'createInstance',
  ),
  add: createApiCallActions<SalesObjectivesRuleModel>(
    'salesObjectivesRule',
    'add',
  ),
  update: createApiCallActions<SalesObjectivesRuleModel>(
    'salesObjectivesRule',
    'update',
  ),
  delete: createApiCallActions<boolean>('salesObjectivesRule', 'delete'),
};

export type SalesObjectivesRuleAction = typeof salesObjectivesRuleActions;
