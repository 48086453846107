import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductCategoryService } from 'src/app/shared/store/product-category/product-category.service';
import {
  ProductCategoryDocumentType,
  ProductCategoryModel,
} from 'src/app/shared/store/product-category/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ActionButton } from '../../../../shared/components/modal-content/action-button';
import { ModalContentComponent } from '../../../../shared/components/modal-content/modal-content.component';
import { toFirstLetterLower } from '../../../../shared/helper/stringhelper';
import { ModalService } from '../../../../shared/service/modal.service';
import { DocumentTypeService } from '../../../../shared/store/document-type/document-type.service';
import { DocumentTypeModel } from '../../../../shared/store/document-type/types';
import { FormService } from '../../../../shared/store/form/form.service';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../../shared/store/typeahead/types';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-product-category-edit',
  templateUrl: './product-category-edit.component.html',
  styleUrls: ['./product-category-edit.component.scss'],
})
export class ProductCategoryEditComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  productCategoryForm: FormGroup;
  productCategory$: Observable<ProductCategoryModel>;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly productCategoryService: ProductCategoryService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly documentTypeService: DocumentTypeService
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.productCategory$ = this.productCategoryService.getById.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'productCategoryUpdate') this.onSubmit();
        if (x == 'productCategoryDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Insurance/ProductCategories/Details',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.productCategoryService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.productCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      productCategoryType: [1, [Validators.required]],
      productCategoryEnumKey: [],
      sequence: [99],
      icon: [''],
      productCategoryDocumentTypes: this.formBuilder.array([]),
    });

    this.productCategory$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@productCategoryEdit', x.name);
        this.productCategoryForm.patchValue({
          name: x.name,
          description: x.description,
          productCategoryType: x.productCategoryType,
          productCategoryEnumKey: x.productCategoryEnumKey,
          sequence: x.sequence,
          icon: x.icon,
        });
        const existingProductCategoryDocumentTypes =
          x.productCategoryDocumentTypes;
        let patchProductCategoryDocumentTypes: any[] = [];
        for (let i = 0; i < existingProductCategoryDocumentTypes.length; i++) {
          this.addProductCategoryDocumentType();
          patchProductCategoryDocumentTypes.push({
            documentTypeId:
              existingProductCategoryDocumentTypes[i].documentTypeId,
            documentTypeName:
              existingProductCategoryDocumentTypes[i].documentTypeName,
            isMandatory: existingProductCategoryDocumentTypes[i].mandatory,
            documentFlow: existingProductCategoryDocumentTypes[i].documentFlow,
          });
        }
        this.productCategoryDocumentTypes.patchValue(
          patchProductCategoryDocumentTypes
        );
      });

    this.documentTypeService
      .getDocumentTypes()
      .pipe(untilDestroyed(this))
      .subscribe((documentTypeModel) => {
        // console.log(x);
        let pcdts: any[] = [];
        let values =
          this.productCategoryForm.value.productCategoryDocumentTypes;

        documentTypeModel.forEach((x) => {
          let existing = values.filter((pcdt) => pcdt.documentTypeId === x.id);
          if (existing.length > 0) {
            pcdts.push(...existing);
          } else {
            pcdts.push({
              documentTypeId: x.id,
              documentTypeName: x.name,
              isMandatory: true,
              documentFlow: 'ToReceive',
            });
          }
        });
        this.productCategoryDocumentTypes.clear();
        for (let i = 0; i < pcdts.length; i++) {
          this.addProductCategoryDocumentType();
        }
        this.productCategoryDocumentTypes.patchValue(pcdts);
      });
  }

  onSubmit() {
    if (this.productCategoryForm.invalid) {
      this.productCategoryForm.markAllAsTouched();
      this.formService.setEntityErrors('PRODUCT_CATEGORY');
      this.formService.countErrors(this.productCategoryForm, true);
      return;
    }

    let productCategoryDocumentTypes: ProductCategoryDocumentType[] = [];

    this.productCategoryForm.value.productCategoryDocumentTypes.forEach((x) => {
      const productCategoryDocumentType: ProductCategoryDocumentType = {
        productCategoryId: this.route.snapshot.params['id'],
        documentTypeId: x.documentTypeId,
        documentFlow: x.documentFlow,
        mandatory: x.isMandatory,

        //Others
        id: 0,
        documentTypeName: x.documentTypeName,
        documentTypeDescription: '',
        productCategoryName: '',
      };
      productCategoryDocumentTypes.push(productCategoryDocumentType);
    });

    const productCategoryModel: ProductCategoryModel = {
      id: this.route.snapshot.params['id'],
      name: this.productCategoryForm.value.name!,
      description: this.productCategoryForm.value.description!,
      productCategoryType: this.productCategoryForm.value.productCategoryType!,
      productCategoryEnumKey:
        this.productCategoryForm.value.productCategoryEnumKey!,
      sequence: this.productCategoryForm.value.sequence!,
      icon: this.productCategoryForm.value.icon!,

      productCategoryDocumentTypes: productCategoryDocumentTypes,
      //Others
      productCategoryTypeName: '',
      insuranceCoverages: [],
    };

    let result$ = this.productCategoryService.update.call(productCategoryModel);
    result$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate([
          '/Insurance/ProductCategories/Details',
          result.id,
        ]);
      });
  }

  ngOnDestroy(): void {
    this.productCategoryService.update.reset();
    this.documentTypeService.setDocumentTypes([]);
    this.breadcrumbService.set('@productCategoryEdit', ' ');
  }

  openModalChoiceDocumentType() {
    let documentTypes: DocumentTypeModel[] = [];

    this.productCategoryForm.value.productCategoryDocumentTypes.forEach((x) => {
      const documentType: DocumentTypeModel = {
        id: x.documentTypeId,
        name: x.documentTypeName,
        description: '',
      };
      documentTypes.push(documentType);
    });

    this.documentTypeService.setDocumentTypes(documentTypes);

    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'SelectDocumentTypeEditContentComponent'
      );
    modalRef.componentInstance.title = 'Sélection des documents';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('documentType') + 'Update',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          modalRef.close();
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  addProductCategoryDocumentType() {
    const productCategoryDocumentTypeForm = this.formBuilder.group({
      documentTypeId: [0],
      documentTypeName: [''],
      isMandatory: [true],
      documentFlow: ['ToReceive'],
    });

    this.productCategoryDocumentTypes.push(productCategoryDocumentTypeForm);
  }

  removeProductCategoryDocumentType(index: number) {
    this.productCategoryDocumentTypes.removeAt(index);
  }

  get productCategoryDocumentTypes() {
    return this.productCategoryForm.controls[
      'productCategoryDocumentTypes'
    ] as FormArray;
  }
}
