import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import { View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import {
  Domain,
  Operation,
  OperationModel,
  TypeGroup,
} from '../../store/search/types';
import { SearchService } from '../../store/search/search.service';

@Component({
  selector: 'app-search-custom-filter',
  templateUrl: './search-custom-filter.component.html',
  styleUrls: ['./search-custom-filter.component.scss'],
})
@UntilDestroy()
export class SearchCustomFilterComponent implements OnInit {
  constructor(
    private readonly viewService: ViewService,
    private readonly searchService: SearchService,
  ) {}

  view$: Observable<View>;
  operationModels$: Observable<OperationModel[]>;
  operationModels: OperationModel[];
  operations: Operation[];
  currentTypeGroup: TypeGroup;

  domain: Domain;

  @Input() searchModuleIndex: number;
  @Output() removeFilter = new EventEmitter<number>();

  ngOnInit(): void {
    this.domain = {
      field: '',
      fieldLabel: '',
      operator: { id: '', label: '', numberOfValues: 1 },
      value: '',
      value2: '',
      connector: 'Or',
      text: '',
      text2: '',
      isActive: false,
    };
    this.view$ = this.viewService.getViews.value$;

    this.operationModels$ = this.searchService.operations.call();

    combineLatest([this.view$, this.operationModels$])
      .pipe(untilDestroyed(this))
      .subscribe(([view, operations]) => {
        if (!!view) {
          this.domain.field = view.fields[0].name;
          this.domain.fieldLabel = view.fields[0].label;
          this.currentTypeGroup = view.fields[0].type;
          this.operationModels = operations;
          if (!!this.operationModels) {
            let operationModel =
              this.operationModels[
                this.operationModels.findIndex(
                  (x) => x.type == view.fields[0].type,
                )
              ];
            this.operations = operationModel.operations;
            this.domain.operator = this.operations[0];
          }
        }
      });
  }
  removeCustomFilter() {
    this.removeFilter.emit(this.searchModuleIndex);
  }

  onNgModelChange(value) {
    this.domain.text = value;
  }
  onNgModelChange2(value) {
    this.domain.text2 = value;
  }

  onFieldSelect() {
    this.view$.pipe(untilDestroyed(this)).subscribe((x) => {
      x.fields.forEach((field) => {
        if (field.name == this.domain.field) {
          this.operations =
            this.operationModels[
              this.operationModels.findIndex((x) => x.type == field.type)
            ].operations;
          this.currentTypeGroup = field.type;
          this.domain.fieldLabel = field.label;
          this.domain.operator = this.operations[0];
        }
      });
    });
  }
}
