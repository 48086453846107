import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { contractActions } from './contract.actions';
import {
  CarInsuranceContractModel,
  ContractModel,
  ContractStatusFlow,
  CreateContractFormModel,
  HealthInsuranceContractDetailModel,
  HouseInsuranceContractModel,
} from './types';

export interface IContractState extends IState<ContractModel> {
  getByLeadId: GenericState<ContractModel>;
  getById: GenericState<ContractModel>;
  getByUniqueId: GenericState<ContractModel>;
  getHealthDetail: GenericState<HealthInsuranceContractDetailModel[]>;
  getCarDetail: GenericState<CarInsuranceContractModel>;
  getHouseDetail: GenericState<HouseInsuranceContractModel>;
  getStatusFlow: GenericState<ContractStatusFlow[]>;
  getStatusActions: GenericState<any[]>;
  getContractModel: GenericState<CreateContractFormModel>;
  getHealthContractModel: GenericState<HealthInsuranceContractDetailModel[]>;
  getCarContractModel: GenericState<CarInsuranceContractModel>;
  getHouseContractModel: GenericState<HouseInsuranceContractModel>;
  createContract: GenericState<ContractModel>;
  createHealthContract: GenericState<ContractModel>;
  createCarContract: GenericState<ContractModel>;
  createHouseContract: GenericState<ContractModel>;
  updateContract: GenericState<ContractModel>;
  updateHealthContract: GenericState<ContractModel>;
  updateCarContract: GenericState<ContractModel>;
  updateHouseContract: GenericState<ContractModel>;
  getIfDataReadOnlyFromContractStatus: GenericState<boolean>;
}

export const contractInitialState: IContractState = {
  getByLeadId: new GenericState<ContractModel>(),
  getById: new GenericState<ContractModel>(),
  getByUniqueId: new GenericState<ContractModel>(),
  getHealthDetail: new GenericState<HealthInsuranceContractDetailModel[]>(),
  getCarDetail: new GenericState<CarInsuranceContractModel>(),
  getHouseDetail: new GenericState<HouseInsuranceContractModel>(),
  getStatusFlow: new GenericState<ContractStatusFlow[]>(),
  getStatusActions: new GenericState<any[]>(),
  getContractModel: new GenericState<CreateContractFormModel>(),
  getHealthContractModel: new GenericState<
    HealthInsuranceContractDetailModel[]
  >(),
  getCarContractModel: new GenericState<CarInsuranceContractModel>(),
  getHouseContractModel: new GenericState<HouseInsuranceContractModel>(),
  createContract: new GenericState<ContractModel>(),
  createHealthContract: new GenericState<ContractModel>(),
  createCarContract: new GenericState<ContractModel>(),
  createHouseContract: new GenericState<ContractModel>(),
  updateContract: new GenericState<ContractModel>(),
  updateHealthContract: new GenericState<ContractModel>(),
  updateCarContract: new GenericState<ContractModel>(),
  updateHouseContract: new GenericState<ContractModel>(),
  getIfDataReadOnlyFromContractStatus: new GenericState<boolean>(),
};

const contractReducers = [
  ...onApiCall<ContractModel>(contractActions.getByLeadId, 'getByLeadId'),
  ...onApiCall<ContractModel>(contractActions.getById, 'getById'),
  ...onApiCall<ContractModel>(contractActions.getByUniqueId, 'getByUniqueId'),
  ...onApiCall<HealthInsuranceContractDetailModel[]>(
    contractActions.getHealthDetail,
    'getHealthDetail',
  ),
  ...onApiCall<CarInsuranceContractModel>(
    contractActions.getCarDetail,
    'getCarDetail',
  ),
  ...onApiCall<HouseInsuranceContractModel>(
    contractActions.getHouseDetail,
    'getHouseDetail',
  ),
  ...onApiCall<ContractStatusFlow[]>(
    contractActions.getStatusFlow,
    'getStatusFlow',
  ),
  ...onApiCall<any[]>(contractActions.getStatusActions, 'getStatusActions'),
  ...onApiCall<ContractModel>(
    contractActions.getContractModel,
    'getContractModel',
  ),
  ...onApiCall<HealthInsuranceContractDetailModel[]>(
    contractActions.getHealthContractModel,
    'getHealthContractModel',
  ),
  ...onApiCall<CarInsuranceContractModel>(
    contractActions.getCarContractModel,
    'getCarContractModel',
  ),
  ...onApiCall<HouseInsuranceContractModel>(
    contractActions.getHouseContractModel,
    'getHouseContractModel',
  ),
  ...onApiCall<ContractModel>(contractActions.createContract, 'createContract'),
  ...onApiCall<ContractModel>(
    contractActions.createHealthContract,
    'createHealthContract',
  ),
  ...onApiCall<ContractModel>(
    contractActions.createCarContract,
    'createCarContract',
  ),
  ...onApiCall<ContractModel>(
    contractActions.createHouseContract,
    'createHouseContract',
  ),
  ...onApiCall<ContractModel>(contractActions.updateContract, 'updateContract'),
  ...onApiCall<ContractModel>(
    contractActions.updateHealthContract,
    'updateHealthContract',
  ),
  ...onApiCall<ContractModel>(
    contractActions.updateCarContract,
    'updateCarContract',
  ),
  ...onApiCall<ContractModel>(
    contractActions.updateHouseContract,
    'updateHouseContract',
  ),
  ...onApiCall<boolean>(
    contractActions.getIfDataReadOnlyFromContractStatus,
    'getIfDataReadOnlyFromContractStatus',
  ),
];

export const contractReducer = createReducer(
  contractInitialState,
  ...contractReducers,
) as ActionReducer<IContractState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return contractReducer(state, action);
}
