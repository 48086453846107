import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ISalesObjectivesRuleState } from './sales-objectives-rule.reducer';
import {
  salesObjectivesRuleFeatureKey,
  SalesObjectivesRuleModel,
} from './types';

const salesObjectivesRuleFeatureState =
  createFeatureSelector<ISalesObjectivesRuleState>(
    salesObjectivesRuleFeatureKey,
  );

export const salesObjectivesRuleSelectors = {
  getById: createApiCallSelectors<number, ISalesObjectivesRuleState>(
    salesObjectivesRuleFeatureState,
    'getById',
  ),
  getByUserId: createApiCallSelectors<
    number | undefined,
    ISalesObjectivesRuleState
  >(salesObjectivesRuleFeatureState, 'getByUserId'),
  createInstance: createApiCallSelectors<
    {
      salesObjectivesUserId: string | undefined;
      startDate: string | undefined;
    },
    ISalesObjectivesRuleState
  >(salesObjectivesRuleFeatureState, 'createInstance'),
  add: createApiCallSelectors<
    SalesObjectivesRuleModel,
    ISalesObjectivesRuleState
  >(salesObjectivesRuleFeatureState, 'add'),
  update: createApiCallSelectors<
    SalesObjectivesRuleModel,
    ISalesObjectivesRuleState
  >(salesObjectivesRuleFeatureState, 'update'),
  delete: createApiCallSelectors<boolean, ISalesObjectivesRuleState>(
    salesObjectivesRuleFeatureState,
    'delete',
  ),
};
