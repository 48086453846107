import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AccountMovesModule } from './account-move/account-move.module';
import { ApplicationFeeRuleModule } from './application-fee-rule/application-fee-rule.module';
import { CashJournalModule } from './cash-journal/cash-journal.module';
import { CommissionPlanRuleModule } from './commission-plan-rule/commission-plan-rule.module';
import { CommissionModule } from './commission/commission.module';
import { FinanceRoutingModule } from './finance-routing.module';
import { InvoiceModule } from './invoice/invoice.module';
import { PaymentModeComponent } from './payment-mode/payment-mode.component';
import { PaymentModule } from './payment/payment.module';
import { AccountModule } from './account/account.module';
import { CompanyCommissionModule } from './company-commission/company-commission.module';
import { SalesObjectivesModule } from './sales-objectives/sales-objectives.module';

@NgModule({
  declarations: [PaymentModeComponent],
  imports: [
    CommonModule,
    FinanceRoutingModule,
    SharedModule,
    PaymentModule,
    InvoiceModule,
    CommissionModule,
    CommissionPlanRuleModule,
    ApplicationFeeRuleModule,
    CashJournalModule,
    AccountMovesModule,
    AccountModule,
    CompanyCommissionModule,
    SalesObjectivesModule,
  ],
})
export class FinanceModule {}
