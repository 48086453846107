import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { formatDate } from 'src/app/shared/helper/datehelper';
import { CommissionService } from 'src/app/shared/store/commissions/commission/commission.service';
import { ReportingService } from 'src/app/shared/store/reporting/reporting.service';
import { Period } from 'src/app/shared/store/reporting/types';
import { SalesTeamMember } from 'src/app/shared/store/sales-team-member/types';
import { SalesTeamService } from 'src/app/shared/store/sales-team/sales-team.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { ListItem } from 'src/app/shared/store/typeahead/types';
import { View } from 'src/app/shared/store/view/types';
import { ViewService } from 'src/app/shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@UntilDestroy()
@Component({
  selector: 'app-company-commission-subheader',
  templateUrl: './company-commission-subheader.component.html',
  styleUrl: './company-commission-subheader.component.scss',
})
export class CompanyCommissionSubHeaderComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  periods$: Observable<Period[]>;
  salesTeamListItems$: Observable<ListItem[]>;
  salesTeamMembers$: Observable<SalesTeamMember[]>;

  periodLabel: string;
  periods: Period[];
  defaultPeriod: string;
  startDate!: Date;
  companyCommissionFilterPeriod: string;
  applicationFeesFilterPeriod: string;
  endDate!: Date;

  salesTeamIds: number[] = [];
  salesTeamIdDefaultValue: number = -1; //id
  salesTeamMemberUserIds: number[] = [];
  salesTeamMemberUserIdDefaultValue: number = 0; //userId

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly reportingService: ReportingService,
    private readonly commissionService: CommissionService,
    private readonly salesTeamService: SalesTeamService,
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.periods$ = this.reportingService.getPeriods.value$;

    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 30);

    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate());

    this.salesTeamIds = [this.salesTeamIdDefaultValue];
    this.salesTeamMemberUserIds = [this.salesTeamMemberUserIdDefaultValue];

    this.reportingService.getPeriods.call(new Date());

    this.periodLabel = formatPeriodLabel(this.startDate, this.endDate);
    this.defaultPeriod = 'Last30Days';

    this.salesTeamListItems$ =
      this.salesTeamService.getCompanyCommissionList.value$;
    this.salesTeamMembers$ =
      this.salesTeamService.getCompanyCommissionSalesTeamMemberList.value$;

    this.salesTeamService.getCompanyCommissionList.call();
    this.salesTeamService.getCompanyCommissionSalesTeamMemberList.call(
      this.salesTeamIdDefaultValue,
    );

    this.companyCommissionFilterPeriod =
      this.startDate === this.endDate
        ? `SubscriptionDate=${formatDate(this.startDate, 'ToSqlDate')}`
        : `SubscriptionDate>=${formatDate(
            this.startDate,
            'ToSqlDate',
          )},SubscriptionDate<=${formatDate(this.endDate, 'ToSqlDate')}`;

    this.applicationFeesFilterPeriod =
      this.startDate === this.endDate
        ? `CreationDate=${formatDate(this.startDate, 'ToSqlDate')}`
        : `CreationDate>=${formatDate(
            this.startDate,
            'ToSqlDate',
          )},CreationDate<=${formatDate(this.endDate, 'ToSqlDate')}`;

    this.getReportInfo();

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });
    this.periods$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((periods) => {
        this.periods = periods;
      });
    // this.commissionService.getApplicationFeesReport.call(filterString);
  }

  ngOnDestroy(): void {}

  onPeriodChange(newValue) {
    this.periods.forEach((period) => {
      if (period.value == newValue.target.value) {
        this.startDate = period.startDate;
        this.endDate = period.endDate;
      }
    });
    this.periodLabel = formatPeriodLabel(
      new Date(this.startDate),
      new Date(this.endDate),
    );
    this.getReportInfo();
  }

  onSalesTeamIdsChange() {
    this.buildSalesTeamIds();

    this.getReportInfo();
  }

  private buildSalesTeamIds() {
    const lastElement = this.salesTeamIds.slice(-1)[0];
    if (lastElement === this.salesTeamIdDefaultValue) {
      this.salesTeamIds = [this.salesTeamIdDefaultValue];
      return;
    }

    //then remove the this.salesTeamIdDefaultValue if in []
    const indexMinus1 = this.salesTeamIds.findIndex(
      (value) => value === this.salesTeamIdDefaultValue,
    );
    if (indexMinus1 > -1) {
      this.salesTeamIds.splice(indexMinus1, 1);
      this.salesTeamIds = [...this.salesTeamIds]; // refresh
    }

    if (this.salesTeamIds.length == 0) {
      this.salesTeamIds = [this.salesTeamIdDefaultValue];
      return;
    }
    return;
  }

  onSalesTeamMembersIdsChange() {
    this.buildSalesTeamMembersIds();

    this.getReportInfo();
  }
  private buildSalesTeamMembersIds() {
    const lastElement = this.salesTeamMemberUserIds.slice(-1)[0];
    if (lastElement === this.salesTeamMemberUserIdDefaultValue) {
      this.salesTeamMemberUserIds = [this.salesTeamMemberUserIdDefaultValue];
      return;
    }

    //then remove the this.salesTeamMemberUserIdDefaultValue if in []
    const indexMinus1 = this.salesTeamMemberUserIds.findIndex(
      (value) => value === this.salesTeamMemberUserIdDefaultValue,
    );
    if (indexMinus1 > -1) {
      this.salesTeamMemberUserIds.splice(indexMinus1, 1);
      this.salesTeamMemberUserIds = [...this.salesTeamMemberUserIds]; // refresh
    }

    if (this.salesTeamMemberUserIds.length == 0) {
      this.salesTeamMemberUserIds = [this.salesTeamMemberUserIdDefaultValue];
      return;
    }
    return;
  }

  private getReportInfo() {
    this.companyCommissionFilterPeriod =
      this.startDate === this.endDate
        ? `SubscriptionDate=${formatDate(this.startDate, 'ToSqlDate')}`
        : `SubscriptionDate>=${formatDate(
            this.startDate,
            'ToSqlDate',
          )},SubscriptionDate<=${formatDate(this.endDate, 'ToSqlDate')}`;

    this.applicationFeesFilterPeriod =
      this.startDate === this.endDate
        ? `CreationDate=${formatDate(this.startDate, 'ToSqlDate')}`
        : `CreationDate>=${formatDate(
            this.startDate,
            'ToSqlDate',
          )},CreationDate<=${formatDate(this.endDate, 'ToSqlDate')}`;

    let companyCommissionFilterString: string =
      this.companyCommissionFilterPeriod;
    let applicationFeesfilterString: string = this.applicationFeesFilterPeriod;
    if (
      this.salesTeamIds.length > 0 &&
      this.salesTeamIds.findIndex(
        (value) => value === this.salesTeamIdDefaultValue,
      ) === -1
    ) {
      companyCommissionFilterString = companyCommissionFilterString + ',(';
      applicationFeesfilterString = applicationFeesfilterString + ',(';
      for (let i = 0; i < this.salesTeamIds.length; i++) {
        companyCommissionFilterString =
          companyCommissionFilterString + `SalesTeamId=${this.salesTeamIds[i]}`;
        applicationFeesfilterString =
          applicationFeesfilterString +
          `ContractSalesTeamId=${this.salesTeamIds[i]}`;

        if (i !== this.salesTeamIds.length - 1) {
          companyCommissionFilterString = companyCommissionFilterString + '|';
          applicationFeesfilterString = applicationFeesfilterString + '|';
        }
      }
      companyCommissionFilterString = companyCommissionFilterString + ')';
      applicationFeesfilterString = applicationFeesfilterString + ')';
    }
    if (
      this.salesTeamMemberUserIds.length > 0 &&
      this.salesTeamMemberUserIds.findIndex(
        (value) => value === this.salesTeamMemberUserIdDefaultValue,
      ) === -1
    ) {
      companyCommissionFilterString = companyCommissionFilterString + ',(';
      applicationFeesfilterString = applicationFeesfilterString + ',(';
      for (let i = 0; i < this.salesTeamMemberUserIds.length; i++) {
        companyCommissionFilterString =
          companyCommissionFilterString +
          `SalesPersonId=${this.salesTeamMemberUserIds[i]}` +
          '|' +
          `SharedSalesPersonId=${this.salesTeamMemberUserIds[i]}`;
        applicationFeesfilterString =
          applicationFeesfilterString +
          `ContractSalesPersonId=${this.salesTeamMemberUserIds[i]}` +
          '|' +
          `ContractSharedSalesPersonId=${this.salesTeamMemberUserIds[i]}`;

        if (i !== this.salesTeamMemberUserIds.length - 1) {
          companyCommissionFilterString = companyCommissionFilterString + '|';
          applicationFeesfilterString = applicationFeesfilterString + '|';
        }
      }
      companyCommissionFilterString = companyCommissionFilterString + ')';
      applicationFeesfilterString = applicationFeesfilterString + ')';
    }
    this.commissionService.getCompanyCommissions.call(
      companyCommissionFilterString,
    );
    this.commissionService.getApplicationFeesReport.call(
      applicationFeesfilterString,
    );
  }
}

function formatPeriodLabel(startDate: Date, endDate: Date) {
  const fullOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const monthOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };
  const dayOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
  };
  let periodLabel: string = '';
  if (startDate.getFullYear() == endDate.getFullYear()) {
    periodLabel =
      'Du ' +
      startDate.toLocaleDateString('fr-FR', monthOptions) +
      ' au ' +
      endDate.toLocaleDateString('fr-FR', fullOptions);

    if (startDate.getMonth() == endDate.getMonth()) {
      periodLabel =
        'Du ' +
        startDate.toLocaleDateString('fr-FR', dayOptions) +
        ' au ' +
        endDate.toLocaleDateString('fr-FR', fullOptions);

      if (startDate.getDate() == endDate.getDate()) {
        periodLabel =
          'Le ' + startDate.toLocaleDateString('fr-FR', fullOptions);
      }
    }
  }

  if (startDate.getFullYear() != endDate.getFullYear()) {
    periodLabel =
      'Du ' +
      startDate.toLocaleDateString('fr-FR', fullOptions) +
      ' au ' +
      endDate.toLocaleDateString('fr-FR', fullOptions);
  }
  return periodLabel;
}
