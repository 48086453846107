import { Component, type OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs';
import { SalesPersonCommissionPaymentModel } from 'src/app/shared/store/commissions/types';
import { DocumentService } from 'src/app/shared/store/document/document.service';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';

@UntilDestroy()
@Component({
  selector: 'app-salesperson-commission-payment',
  templateUrl: './salesperson-commission-payment.component.html',
  styleUrls: ['./salesperson-commission-payment.component.scss'],
})
export class SalespersonCommissionPaymentComponent implements OnInit {
  rowData: SalesPersonCommissionPaymentModel;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'salesPersonPaymentTemplate') {
          this.getSalesPersonPaymentTemplate();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }
  getSalesPersonPaymentTemplate() {
    const salesPersonCommissionPaymentModel: SalesPersonCommissionPaymentModel =
      this.onGetRowData();
    const getFileSubscription = this.documentService.getFile.value$
      .pipe(
        // tap((x) => {
        //   console.log(x);
        // }),
        filter((x) => !!x),
        take(1)
      )
      .subscribe((result: Blob) => {
        var fileURL = window.URL.createObjectURL(result);
        let tab = window.open();
        if (tab) {
          tab.location.href = fileURL;
        }
        this.documentService.getFile.reset();
      });

    this.documentService.getFile.call(
      salesPersonCommissionPaymentModel.documentId.toString()
    );
  }

  onGetRowData() {
    //copy the data
    const result = this.rowData;

    //when we get the data, reset the data
    this.onSetRowData(undefined);

    //return the copy of the data
    return result;
  }

  onSetRowData(rowData: any) {
    this.rowData = rowData;
  }
}
