import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonType } from '../../../../shared/store/subheader/type';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { ModalContentComponent } from '../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../shared/components/modal-content/action-button';
import { SourceEnum } from '../../../../shared/store/typeahead/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../shared/service/modal.service';
import {
  CashJournalEntry,
  CashJournalModel,
} from '../../../../shared/store/accounting/types';
import { AccountingService } from '../../../../shared/store/accounting/accounting.service';
import { DocumentService } from '../../../../shared/store/document/document.service';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-cash-journal-details',
  standalone: false,
  templateUrl: './cash-journal-details.component.html',
  styleUrl: './cash-journal-details.component.scss',
})
export class CashJournalDetailsComponent implements OnInit, OnDestroy {
  salesTeamAccountId: number;
  contactId: number | undefined;
  view$: Observable<View>;

  cashJournalModel$: Observable<CashJournalModel>;

  rowData: CashJournalEntry;

  protected readonly ButtonType = ButtonType;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly accountingService: AccountingService,
    private readonly documentService: DocumentService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.cashJournalModel$ = this.accountingService.getCashJournal.value$;
  }

  ngOnInit(): void {
    this.salesTeamAccountId = this.route.snapshot.params['id'];
    this.accountingService.getCashJournal.call(
      this.salesTeamAccountId.toString(),
    );

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.cashJournalModel$
      .pipe(filterTrue(), untilDestroyed(this))
      .subscribe((x) => {
        this.breadcrumbService.set('@cashJournalDetails', x.accountName);
        this.contactId = x.accountContactId;
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'cashJournalOut') {
          this.openModalCashOut();
        }
        if (x == 'cashJournalIn') {
          this.openModalCashIn();
        }
        if (x == 'cashJournalGotoControl') {
          this.gotoControl();
        }
        if (x == 'documentDownload') {
          this.onClickShowDocument();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  private openModalCashIn() {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'CashJournalAddContentComponent',
      );
    modalRef.componentInstance.title = 'CASH_JOURNAL_LBL_CASH_IN';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'addCashEntry',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      type: 'In',
      contactId: this.contactId,
    };

    modalRef.result
      .then((id) => {
        this.accountingService.getCashJournal.call(
          this.salesTeamAccountId.toString(),
        );
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  private openModalCashOut() {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'CashJournalAddContentComponent',
      );
    modalRef.componentInstance.title = 'CASH_JOURNAL_LBL_CASH_OUT';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'addCashEntry',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      type: 'Out',
      contactId: this.contactId,
    };

    modalRef.result
      .then((id) => {
        this.accountingService.getCashJournal.call(
          this.salesTeamAccountId.toString(),
        );
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  ngOnDestroy(): void {}

  private onClickShowDocument() {
    const cashJournalEntry: CashJournalEntry = this.onGetRowData();

    const getFileSubscription = this.documentService.getFile.value$
      .pipe(
        // tap((x) => {
        //   console.log(x);
        // }),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result: Blob) => {
        var fileURL = window.URL.createObjectURL(result);
        let tab = window.open();
        if (tab) {
          tab.location.href = fileURL;
        }
        this.documentService.getFile.reset();
      });

    this.documentService.getFile.call(cashJournalEntry.documentId.toString());
  }

  private gotoControl() {
    this.router.navigate([
      'Finance/CashJournals/Details',
      this.salesTeamAccountId,
      'Control',
    ]);
  }

  onGetRowData() {
    //copy the data
    const result = this.rowData;

    //when we get the data, reset the data
    this.onSetRowData(undefined);

    //return the copy of the data
    return result;
  }

  onSetRowData(rowData: any) {
    this.rowData = rowData;
  }
}
