import { Component, OnDestroy, OnInit } from '@angular/core';
import { AmountType } from '../../../../../shared/store/commissions/commission-plan-rule/types';
import { Observable } from 'rxjs';
import { SalesObjectivesRuleModel } from '../../../../../shared/store/sales-objectives-rule/types';
import { SalesObjectivesRuleService } from '../../../../../shared/store/sales-objectives-rule/sales-objectives-rule.service';
import { View } from '../../../../../shared/store/view/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { filterTrue } from '../../../../../shared/pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-sales-objectives-rule-details',
  templateUrl: './sales-objectives-rule-details.component.html',
  styleUrl: './sales-objectives-rule-details.component.scss',
})
export class SalesObjectivesRuleDetailsComponent implements OnInit, OnDestroy {
  defaultAmountType: AmountType = 'Monetary';

  salesObjectivesRuleModel$: Observable<SalesObjectivesRuleModel>;
  salesObjectivesRuleId: number;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly salesObjectivesRuleService: SalesObjectivesRuleService,
  ) {
    this.salesObjectivesRuleModel$ =
      this.salesObjectivesRuleService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.salesObjectivesRuleId = this.route.snapshot.params['id'];

    this.salesObjectivesRuleService.getById.call(
      this.salesObjectivesRuleId.toString(),
    );

    this.salesObjectivesRuleModel$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@salesObjectivesRuleDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'salesObjectivesRuleEdit') {
          this.router.navigate([
            'Finance/SalesObjectivesRules/Edit',
            this.salesObjectivesRuleId,
          ]);
        }
        if (x == 'salesObjectivesRuleDelete') {
          this.onDelete();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  onDelete() {
    let result$ = this.salesObjectivesRuleService.delete.call(
      this.salesObjectivesRuleId.toString(),
    );

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.salesObjectivesRuleService.delete.reset();
      this.router.navigate(['Finance/SalesObjectivesRules/List']);
    });
  }

  ngOnDestroy(): void {
    this.salesObjectivesRuleService.getById.reset();
    this.salesObjectivesRuleService.delete.reset();
    this.breadcrumbService.set('@salesObjectivesRuleDetails', ' ');
  }
}
