<!--<div class="form-group-title my-1" translate>-->
<!--  SALES_OBJECTIVES_RULE_FIELDSET_SALES_OBJECTIVES_RULES-->
<!--</div>-->
@if (this.salesObjectivesRuleModels$ | async; as salesObjectivesRuleModels) {
  <div class="row mt-2">
    <div class="col-12">
      <app-generic-grid
        style="height: 300px"
        [data]="salesObjectivesRuleModels"
        [tabViewName]="'salesObjectivesRulesTabs'"
      />
    </div>
  </div>
}

<!--<div class="row mt-2">-->
<!--  <div class="col-12">-->
<!--    <table class="table table-striped border-bottom">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th>-->
<!--          <a translate>SALES_OBJECTIVES_RULE_TABLE_DAILY_AMOUNT</a>-->
<!--        </th>-->
<!--        <th>-->
<!--          <a translate>SALES_OBJECTIVES_RULE_TABLE_MONTHLY_AMOUNT</a>-->
<!--        </th>-->
<!--        <th>-->
<!--          <a translate>SALES_OBJECTIVES_RULE_TABLE_YEARLY_AMOUNT</a>-->
<!--        </th>-->
<!--        <th>-->
<!--          <a translate>SALES_OBJECTIVES_RULE_TABLE_START_DATE</a>-->
<!--        </th>-->
<!--        <th>-->
<!--          <a translate>SALES_OBJECTIVES_RULE_TABLE_END_DATE</a>-->
<!--        </th>-->
<!--      </tr>-->
<!--      </thead>-->

<!--      <tbody>-->
<!--      <ng-container *ngIf="this.salesObjectivesRuleModels$ | async as salesObjectivesRuleModels">-->
<!--        <ng-container-->
<!--          *ngFor="let salesObjectivesRuleModel of salesObjectivesRuleModels; trackBy: trackBySalesObjectivesRuleId">-->
<!--          <tr (click)="onClickLinkTo(salesObjectivesRuleModel.id, 'Finance/SalesObjectivesRules/Details/')"-->
<!--              class="row-action">-->
<!--            <td>{{ salesObjectivesRuleModel.dailyAmount | showSignValueType: this.defaultAmountType }}</td>-->
<!--            <td>{{ salesObjectivesRuleModel.monthlyAmount | showSignValueType: this.defaultAmountType }}</td>-->
<!--            <td>{{ salesObjectivesRuleModel.yearlyAmount | showSignValueType: this.defaultAmountType }}</td>-->
<!--            <td>-->
<!--              {{-->
<!--                !!salesObjectivesRuleModel.startDate-->
<!--                  ? (salesObjectivesRuleModel.startDate | date : 'dd/MM/yyyy')-->
<!--                  : '-'-->
<!--              }}-->
<!--            </td>-->
<!--            <td>-->
<!--              {{-->
<!--                !!salesObjectivesRuleModel.endDate-->
<!--                  ? (salesObjectivesRuleModel.endDate | date : 'dd/MM/yyyy')-->
<!--                  : '-'-->
<!--              }}-->
<!--            </td>-->
<!--          </tr>-->
<!--        </ng-container>-->
<!--      </ng-container>-->
<!--      </tbody>-->
<!--    </table>-->


<!--  </div>-->
<!--  <div class="col"></div>-->
<!--</div>-->
