import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { View } from '../../../../shared/store/view/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { take } from 'rxjs/operators';
import { ProductService } from 'src/app/shared/store/product/product.service';
import { ProductModel } from 'src/app/shared/store/product/types';
import { formatDate } from '../../../../shared/helper/datehelper';

@UntilDestroy()
@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
})
export class ProductEditComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  productForm: FormGroup;
  product$: Observable<ProductModel>;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly productService: ProductService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.product$ = this.productService.getById.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'productUpdate') this.onSubmit();
        if (x == 'productDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Insurance/Products/Details',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.productService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.productForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      productLineId: [undefined, [Validators.required]],
      productCategoryId: [undefined, [Validators.required]],
      startDate: [],
      endDate: [],
      taxRate: [''],
    });

    this.product$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@productEdit', x.name);
        this.productForm.patchValue({
          name: x.name,
          productLineId: x.productLineId,
          productCategoryId: x.productCategoryId,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          endDate: formatDate(x.endDate, 'ToInverseSqlDate'),
          taxRate: x.taxRate,
        });
      });
  }

  onSubmit() {
    if (this.productForm.invalid) {
      this.productForm.markAllAsTouched();
      this.formService.countErrors(this.productForm, true);
      return;
    }

    let productModel: ProductModel = {
      id: this.route.snapshot.params['id'],
      name: this.productForm.value.name!,
      productLineId: this.productForm.value.productLineId!,
      productCategoryId: this.productForm.value.productCategoryId!,
      productLineName: '',
      productLineInsuranceCompanyContactName: '',
      productLineProductCategoryName: '',
      startDate:
        this.productForm.value.startDate! === ''
          ? undefined
          : this.productForm.value.startDate!,
      endDate:
        this.productForm.value.endDate! === ''
          ? undefined
          : this.productForm.value.endDate!,
      taxRate: this.productForm.value.taxRate!,
      productCoverages: [],
      statusColor: '',
      isActiveLoc: '',
      notes: '',
    };
    let result$ = this.productService.update.call(productModel);
    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        this.router.navigate(['/Insurance/Products/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.productService.update.reset();
    this.breadcrumbService.set('@productEdit', ' ');
  }
}
