import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesObjectivesRuleModel } from '../../../../../shared/store/sales-objectives-rule/types';
import { SalesObjectivesRuleService } from '../../../../../shared/store/sales-objectives-rule/sales-objectives-rule.service';
import { AmountType } from '../../../../../shared/store/commissions/commission-plan-rule/types';

@Component({
  selector: 'app-sales-objectives-rule-details-sales-objectives-rules',
  templateUrl:
    './sales-objectives-rule-details-sales-objectives-rules.component.html',
  styleUrl:
    './sales-objectives-rule-details-sales-objectives-rules.component.scss',
})
export class SalesObjectivesRuleDetailsSalesObjectivesRulesComponent
  implements OnInit, OnDestroy
{
  defaultAmountType: AmountType = 'Monetary';
  salesObjectivesRuleModels$: Observable<SalesObjectivesRuleModel[]>;
  userId: number;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly salesObjectivesRuleService: SalesObjectivesRuleService,
  ) {
    this.salesObjectivesRuleModels$ =
      this.salesObjectivesRuleService.getByUserId.value$;
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params['id'];
  }

  ngOnDestroy(): void {}

  trackBySalesObjectivesRuleId(index: any, item: SalesObjectivesRuleModel) {
    return item.id;
  }

  onClickLinkTo(salesObjectivesRuleId: number, url: string) {
    this.router.navigate([
      'Finance/SalesObjectivesRules/Details/',
      salesObjectivesRuleId,
    ]);
  }
}
