<app-dashboard-subheader
  [(startDate)]="startDate"
  [(endDate)]="endDate"
  [(campaignId)]="campaignId"
  [(insuranceType)]="insuranceType"
  [(salesTeamId)]="salesTeamId"
  [(salesTeamMemberId)]="salesTeamMemberId"
/>
<app-dashboard
  [startDate]="startDate"
  [endDate]="endDate"
  [campaignId]="campaignId"
  [insuranceType]="insuranceType"
  [salesTeamId]="salesTeamId"
  [salesTeamMemberId]="salesTeamMemberId"
/>
