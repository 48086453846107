import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { Entity, View } from '../../../../shared/store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { ProductService } from 'src/app/shared/store/product/product.service';
import { ProductModel } from 'src/app/shared/store/product/types';

@UntilDestroy()
@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss'],
})
export class ProductCreateComponent implements OnInit, OnDestroy {
  protected readonly Entity = Entity;
  view$: Observable<View>;
  productForm: FormGroup;
  productCreateInstance$: Observable<ProductModel>;

  constructor(
    private readonly router: Router,
    private readonly productService: ProductService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.productCreateInstance$ = this.productService.createInstance.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'productCreatePush') this.onSubmit();
        if (x == 'productList') {
          this.formService.clear();
          this.router.navigate(['Insurance/Products/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@productCreate', x.breadcrumbCreate);
      });

    this.productForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      productLineId: [undefined, [Validators.required]],
      startDate: [],
      endDate: [],
      taxRate: [''],
    });

    this.productService.createInstance.call();
    this.productCreateInstance$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.productForm?.patchValue({
          name: x.name,
          productLineId: x.productLineId ? x.productLineId : undefined,
          productCategoryId: x.productCategoryId,
          startDate: x.startDate,
          endData: x.endDate,
          taxRate: x.taxRate,
        });
      });
  }

  onSubmit() {
    if (this.productForm.invalid) {
      this.productForm.markAllAsTouched();
      this.formService.countErrors(this.productForm, true);
      return;
    }

    this.formService.clear();

    let productModel: ProductModel = {
      id: 0,
      name: this.productForm.value.name!,
      productLineId: this.productForm.value.productLineId!,
      productLineName: '',
      productLineInsuranceCompanyContactName: '',
      productLineProductCategoryName: '',
      startDate:
        this.productForm.value.startDate! === ''
          ? undefined
          : this.productForm.value.startDate!,
      endDate:
        this.productForm.value.endDate! === ''
          ? undefined
          : this.productForm.value.endDate!,
      taxRate: this.productForm.value.taxRate!,
      productCategoryId: 0,
      productCoverages: [],
      statusColor: '',
      isActiveLoc: '',
      notes: '',
    };

    let result$ = this.productService.add.call(productModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Insurance/Products/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.productService.add.reset();
  }
}
