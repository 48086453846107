import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { CommissionRoutingModule } from './commission-routing.module';
import { SalespersonCommissionPaymentComponent } from './salesperson-commission-payment/salesperson-commission-payment.component';

@NgModule({
  declarations: [SalespersonCommissionPaymentComponent],
  imports: [CommonModule, CommissionRoutingModule, SharedModule],
})
export class CommissionModule {}
