<div class="sub-header">
  <div class="sub-header-row px-1 py-2">
    <div class="row">
      <div class="col">
        <app-breadcrumb></app-breadcrumb>
      </div>
      <div class="col">
        <div class="float-end fs-4 text-primary">
          {{ this.periodLabel }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <!-- <div class="d-inline-block mr-2">
          <select
            class="form-control form-control-sm form-select"
            [ngModel]="insuranceType"
            (ngModelChange)="onInsuranceTypeChange($event)"
          >
            <option value="-1" translate>
              DASHBOARD_LBL_SELECT_OPTION_GROUP_ALL_CATEGORY
            </option>
            <option value="2" translate>DASHBOARD_CHOICE_LBL_CAR</option>
            <option value="3" translate>DASHBOARD_CHOICE_LBL_HOUSE</option>
            <option value="1" translate>DASHBOARD_CHOICE_LBL_HEALTH</option>
          </select>
        </div> -->
        <!-- <div
          class="d-inline-block mx-2"
          *ngIf="this.campaignsListItem$ | async as campaigns"
        >
          <select
            class="form-control form-control-sm"
            [ngModel]="campaignId"
            (ngModelChange)="onCampaignIdChange($event)"
          >
            <option
              *ngFor="let campaign of campaigns"
              value="{{ campaign.id }}"
            >
              {{ campaign.name }}
            </option>
          </select>
        </div> -->
        <div
          class="d-inline-block mx-2"
          *ngIf="this.salesTeamListItems$ | async as salesTeams"
        >
          <ng-select
            class="form-control form-control-sm"
            style="width: 200px;"
            bindValue="id"
            bindLabel="name"
            [items]="salesTeams"
            [multiple]="true"
            [selectableGroup]="false"
            [closeOnSelect]="false"
            [clearable]="false"
            [(ngModel)]="salesTeamIds"
            (ngModelChange)="onSalesTeamIdsChange()"
          >
            <ng-template ng-multi-label-tmp let-items="items">
              @if (items.length === 1) {
                {{ items[0].name }}
              }
              @if (items.length > 1) {
                <span translate [translateParams]="{ p1: items.length}">COMPANY_COMMISSION_LBL_SALESTEAM_SELECTED</span>
              }
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected"/> {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
        <div
          class="d-inline-block mx-2"
          *ngIf="this.salesTeamMembers$ | async as salesTeamMembers"
        >
          <ng-select
            class="form-control form-control-sm"
            style="width: 250px;"
            bindValue="userId"
            bindLabel="userContactName"
            [items]="salesTeamMembers"
            [multiple]="true"
            [selectableGroup]="false"
            [closeOnSelect]="false"
            [clearable]="false"
            [(ngModel)]="salesTeamMemberUserIds"
            (ngModelChange)="onSalesTeamMembersIdsChange()"
          >
            <ng-template ng-multi-label-tmp let-items="items">
              @if (items.length === 1) {
                {{ items[0].userContactName }}
              }
              @if (items.length > 1) {
                <span translate [translateParams]="{ p1: items.length}">COMPANY_COMMISSION_LBL_SALESTEAMMEMBER_SELECTED</span>
              }
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected"/> {{ item.userContactName }}
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col">
        <div
          class="d-inline-block float-end fs-4"
          *ngIf="this.periods$ | async as periods"
        >
          <select
            class="form-control form-control-sm form-select"
            [ngModel]="this.defaultPeriod"
            (change)="onPeriodChange($event)"
          >
            <option disabled style="background-color: lightgray" translate>
              DASHBOARD_LBL_SELECT_OPTION_GROUP_STANDARD_PERIOD
            </option>
            <ng-container *ngFor="let period of periods">
              <option
                *ngIf="period.group == 'Périodes standards'"
                value="{{ period.value }}"
              >
                {{ period.label }}
              </option>
            </ng-container>
            <option disabled style="background-color: lightgray" translate>
              DASHBOARD_LBL_SELECT_OPTION_GROUP_RECENTLY
            </option>
            <ng-container *ngFor="let period of periods">
              <option
                *ngIf="period.group == 'Récemment'"
                value="{{ period.value }}"
              >
                {{ period.label }}
              </option>
            </ng-container>
          </select>
        </div>
      </div>
    </div>
  </div>
</div>
