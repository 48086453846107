import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '../../../shared/guard/authentication-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { ModuleId } from '../../../shared/store/navigation/types';
import { DataListingProviderComponent } from '../../../shared/components/data-listing-provider/data-listing-provider.component';
import { SalesObjectivesRuleEditComponent } from './sales-objectives-rule/sales-objectives-rule-edit/sales-objectives-rule-edit.component';
import { SalesObjectivesRuleCreateComponent } from 'src/app/features/finance/sales-objectives/sales-objectives-rule/sales-objectives-rule-create/sales-objectives-rule-create.component';
import { SalesObjectivesRuleDetailsComponent } from './sales-objectives-rule/sales-objectives-rule-details/sales-objectives-rule-details.component';

const routes: Routes = [
  {
    path: 'Finance/SalesObjectivesRules',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.SalesObjectivesRule,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: SalesObjectivesRuleDetailsComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.SalesObjectivesRule,
          breadcrumb: { label: ' ', alias: 'salesObjectivesRuleDetails' },
        },
      },
      {
        path: 'Create',
        component: SalesObjectivesRuleCreateComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.SalesObjectivesRule,
          breadcrumb: { label: ' ', alias: 'salesObjectivesRuleCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: SalesObjectivesRuleEditComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.SalesObjectivesRule,
          breadcrumb: { label: ' ', alias: 'salesObjectivesRuleEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesObjectivesRoutingModule {}
