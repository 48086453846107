import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sales-objectives-rule-edit',
  templateUrl: './sales-objectives-rule-edit.component.html',
  styleUrl: './sales-objectives-rule-edit.component.scss',
})
export class SalesObjectivesRuleEditComponent implements OnInit {
  salesObjectivesRuleId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.salesObjectivesRuleId = this.route.snapshot.params['id'];
  }
}
