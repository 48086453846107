<app-document-subheader/>
<app-custom-actions-flow-lead/>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div
        class="page-container"
        *ngIf="this.leadDetail$ | async as leadDetail"
      >
        <div class="card card-light w-100">
          <app-smart-actions-lead [smartActions]="this.smartActions"/>
          <div class="card-body">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-12 col-md-9 mr-auto">
                  <div class="form-group partner-name active">
                    <div class="form-title pt-0">
                      @if (leadDetail.contactId) {
                        <app-link-to [id]="leadDetail.contactId.toString()"
                                     [label]="leadDetail.name"
                                     [url]="'/Contacts/Contacts/Details/'"
                        />
                      } @else {
                        {{ leadDetail.name }}
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_ADDRESS</label
                    >
                    <div class="col">
                      <div
                        class="form-control-plaintext form-control-sm mb-2 h-100 p-0"
                      >
                        <app-address
                          [address]="leadDetail.address"
                          [address2]="leadDetail.address2"
                          [zipCode]="leadDetail.zipCode"
                          [city]="leadDetail.city"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_PHONE_NUMBER</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        <app-phone-number
                          [phoneNumber]="leadDetail.phoneNumber"
                          [isLinkEnabled]="true"
                        ></app-phone-number>
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_EMAIL</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        <a href="mailto:{{ leadDetail.email }}">{{
                            leadDetail.email
                          }}</a>
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_PROFESSION</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ leadDetail.professionLoc }}
                      </span>
                    </div>
                  </div>

                </div>
                <div class="col">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_CATEGORY</label
                    >
                    <div class="col">
                      <div
                        class="form-control-plaintext form-control-sm badge-list"
                      >
                        <app-badge
                          [message]="leadDetail.productCategoryName"
                          [color]="leadDetail.categoryColor"
                        ></app-badge>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_SALETEAM_MEMBER</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ leadDetail.salesPersonContactName }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_SALETEAM</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ leadDetail.salesTeamName }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_EFFECTIVE_DATE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{
                          leadDetail.expectedContractStartDate
                            | date : "dd/MM/yyyy"
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <app-lead-tabs-details [category]="leadDetail.productCategoryProductCategoryEnumKey"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.leadDetail$ | async as leadDetail">
      <app-comment-panel
        [entityType]="'Lead'"
        [entityId]="leadDetail.id.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
