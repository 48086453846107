<!-- AG Grid Angular Component -->
@if (isVisible$ | async) {
<ag-grid-angular
  class="ag-theme-alpine data-grid"
  [domLayout]="'autoHeight'"
  [gridOptions]="gridOptions"
  [rowData]="this.data"
  (gridReady)="onGridReady($event)"
  (gridSizeChanged)="onGridSizeChanged($event)"
  [overlayNoRowsTemplate]="this.noRowsTemplate"
  [pagination]="this.data.length >= this.pageSize"
  [paginationPageSize]="this.pageSize"
  [paginationPageSizeSelector]="false"
  [tooltipShowMode]="'whenTruncated'"
  [tooltipShowDelay]="500"
  [rowHeight]="this.rowHeight"
  [context]="context"
  (rowDragEnter)="onRowDragEnter($event)"
  (dragStopped)="onDragStopped($event)"
/>
}
