import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SourceEnum } from '../../../store/typeahead/types';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../store/view/views.service';
import { FormService } from '../../../store/form/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesObjectivesRuleService } from '../../../store/sales-objectives-rule/sales-objectives-rule.service';
import { debounceTime, distinctUntilChanged, filter, Observable } from 'rxjs';
import { View } from '../../../store/view/types';
import { SalesObjectivesRuleModel } from '../../../store/sales-objectives-rule/types';
import { AmountType } from '../../../store/commissions/commission-plan-rule/types';
import { getAmountSign } from '../../../helper/amounthelper';
import { datesValidator } from '../../../validators/dates-validator.validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs/operators';
import { formatDate } from '../../../helper/datehelper';
import { ModalService } from '../../../service/modal.service';

@UntilDestroy()
@Component({
  selector: 'app-sales-objectives-rule-form-content',
  templateUrl: './sales-objectives-rule-form-content.component.html',
  styleUrl: './sales-objectives-rule-form-content.component.scss',
})
export class SalesObjectivesRuleFormContentComponent
  implements OnInit, OnDestroy
{
  @Input() modalRandomIdentifier: number;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input() userId: number;
  @Input() inputSalesObjectivesRuleId?: number = undefined;

  view$: Observable<View>;
  salesObjectivesRuleForm: FormGroup;

  salesObjectivesRuleModel$: Observable<SalesObjectivesRuleModel>;
  createSalesObjectivesRuleInstance$: Observable<SalesObjectivesRuleModel>;

  isEditMode: boolean = false;
  salesObjectivesRuleId: string;

  defaultAmountType: AmountType = 'Monetary';
  amountPlaceholder: string;
  amountSuffix: string;

  previousExistingDailyAmount: number;
  previousExistingMonthlyAmount: number;
  previousExistingYearlyAmount: number;

  constructor(
    private readonly salesObjectivesRuleService: SalesObjectivesRuleService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly dynamicService: ModalService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.salesObjectivesRuleModel$ =
      this.salesObjectivesRuleService.getById.value$;
    this.createSalesObjectivesRuleInstance$ =
      this.salesObjectivesRuleService.createInstance.value$;
  }

  ngOnInit(): void {
    //Initialisation du suffixe et du placeholder
    this.amountPlaceholder = this.amountSuffix = getAmountSign(
      this.defaultAmountType,
    );

    //Déclaration du formulaire
    this.salesObjectivesRuleForm = this.formBuilder.group(
      {
        dailyAmount: [undefined, [Validators.required]],
        monthlyAmount: [undefined, [Validators.required]],
        yearlyAmount: [undefined, [Validators.required]],
        startDate: [undefined, [Validators.required]],
        endDate: [undefined],
      },
      { validators: datesValidator('startDate', 'endDate') },
    );
    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.salesObjectivesRuleId =
      this.inputSalesObjectivesRuleId?.toString() ??
      this.route.snapshot.params['id'];

    this.isEditMode = !!this.salesObjectivesRuleId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.salesObjectivesRuleService.getById.call(this.salesObjectivesRuleId);
    } else {
      this.salesObjectivesRuleService.createInstance.call({
        salesObjectivesUserId: this.userId,
        startDate: undefined,
      });
    }

    //On appelle les tabs
    if (this.source === SourceEnum.Component) {
      this.salesObjectivesRuleService.getByUserId.call(this.userId?.toString());
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (
          x == 'salesObjectivesRuleCreate' ||
          x == 'salesObjectivesRuleUpdate'
        )
          this.onSubmit();
        if (x == 'salesObjectivesRuleList') {
          this.formService.clear();
          this.router.navigate(['Finance/SalesObjectivesRules/']);
        }
        if (x == 'salesObjectivesRuleDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Finance/SalesObjectivesRules/Details',
            this.salesObjectivesRuleId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set(
            '@salesObjectivesRuleCreate',
            x.breadcrumbCreate,
          );
      });

    this.salesObjectivesRuleModel$
      .pipe(
        filter(() => this.isEditMode),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@salesObjectivesRuleEdit', x.name);
        this.salesObjectivesRuleForm.patchValue({
          dailyAmount: x.dailyAmount,
          monthlyAmount: x.monthlyAmount,
          yearlyAmount: x.yearlyAmount,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          endDate: !!x.endDate
            ? formatDate(x.endDate, 'ToInverseSqlDate')
            : undefined,
        });
      });

    this.createSalesObjectivesRuleInstance$
      .pipe(
        filter(() => !this.isEditMode),
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        const existingDailyAmount =
          this.salesObjectivesRuleForm?.get('dailyAmount')?.value;
        if (
          !existingDailyAmount ||
          existingDailyAmount === this.previousExistingDailyAmount
        ) {
          this.salesObjectivesRuleForm?.patchValue({
            dailyAmount: x.dailyAmount ? x.dailyAmount : undefined,
          });
        }

        const existingMonthlyAmount =
          this.salesObjectivesRuleForm?.get('monthlyAmount')?.value;
        if (
          !existingMonthlyAmount ||
          existingMonthlyAmount === this.previousExistingMonthlyAmount
        ) {
          this.salesObjectivesRuleForm?.patchValue({
            monthlyAmount: x.monthlyAmount ? x.monthlyAmount : undefined,
          });
        }

        const existingYearlyAmount =
          this.salesObjectivesRuleForm?.get('yearlyAmount')?.value;
        if (
          !existingYearlyAmount ||
          existingYearlyAmount === this.previousExistingYearlyAmount
        ) {
          this.salesObjectivesRuleForm?.patchValue({
            yearlyAmount: x.yearlyAmount ? x.yearlyAmount : undefined,
          });
        }

        this.salesObjectivesRuleForm?.patchValue({
          // dailyAmount: x.dailyAmount ? x.dailyAmount : undefined,
          // monthlyAmount: x.monthlyAmount ? x.monthlyAmount : undefined,
          // yearlyAmount: x.yearlyAmount ? x.yearlyAmount : undefined,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
        });

        this.previousExistingDailyAmount = x.dailyAmount;
        this.previousExistingMonthlyAmount = x.monthlyAmount;
        this.previousExistingYearlyAmount = x.yearlyAmount;
      });

    //When the startDate change, we call to know if there is a default value
    this.salesObjectivesRuleForm
      .get('startDate')
      ?.valueChanges?.pipe(
        debounceTime(800),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      ?.subscribe((startDate) => {
        this.salesObjectivesRuleService.createInstance.call({
          salesObjectivesUserId: this.userId,
          startDate: startDate,
        });
      });
  }

  onSubmit() {
    if (this.salesObjectivesRuleForm.invalid) {
      this.salesObjectivesRuleForm.markAllAsTouched();
      this.formService.setEntityErrors('SALES_OBJECTIVES_RULE');
      this.formService.countErrors(this.salesObjectivesRuleForm, true);
      return;
    }

    const salesObjectivesRuleModel: SalesObjectivesRuleModel = {
      id: this.salesObjectivesRuleId ? +this.salesObjectivesRuleId : 0,
      userId: this.userId,
      dailyAmount: this.salesObjectivesRuleForm.value.dailyAmount!,
      monthlyAmount: this.salesObjectivesRuleForm.value.monthlyAmount!,
      yearlyAmount: this.salesObjectivesRuleForm.value.yearlyAmount!,
      startDate: this.salesObjectivesRuleForm.value.startDate!,
      endDate:
        this.salesObjectivesRuleForm.value.endDate! === ''
          ? undefined
          : this.salesObjectivesRuleForm.value.endDate!,
      name: '',
    };

    let result$: Observable<SalesObjectivesRuleModel>;
    if (this.isEditMode) {
      result$ = this.salesObjectivesRuleService.update.call(
        salesObjectivesRuleModel,
      );
    } else {
      result$ = this.salesObjectivesRuleService.add.call(
        salesObjectivesRuleModel,
      );
    }

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.formService.clear();
        switch (this.source) {
          case SourceEnum.Component:
            this.router.navigate([
              '/Finance/SalesObjectivesRules/Details',
              result.id,
            ]);
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              this.modalRandomIdentifier,
              result.id.toString(),
            );
            break;
          default:
            this.router.navigate([
              '/Finance/SalesObjectivesRules/Details',
              result.id,
            ]);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.salesObjectivesRuleService.add.reset();
    this.salesObjectivesRuleService.update.reset();

    if (this.isEditMode) {
      this.salesObjectivesRuleService.getById.reset();
    } else {
      this.salesObjectivesRuleService.createInstance.reset();
    }

    this.breadcrumbService.set('@salesObjectivesRuleEdit', ' ');
    this.breadcrumbService.set('@salesObjectivesRuleCreate', ' ');
  }
}
