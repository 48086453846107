<ng-container *ngIf="this.contactDetail$ | async as contact">
  <div class="row" *ngIf="contact.isCompany">
    <div class="col">
      <div class="form-group">
        <app-badge [message]="'CONTACT_LBL_IS_COMPANY'"></app-badge>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group">
        <div class="form-title">{{ contact.name }}</div>
      </div>
    </div>
    <div class="col-auto ms-auto">
      <div class="avatar-upload">
        <div class="avatar-preview rounded-circle shadow-sm position-relative">
          <div
            id="imagePreview"
            class="rounded-circle"
            [ngStyle]="{
              'background-image':
                'url(' + this.apiUrl + contact.picturePath + ')'
            }"
          >
            <ng-container *ngIf="!contact.picturePath">
              <img
                *ngIf="contact.isCompany"
                id="pictureCompany"
                class="picture-control active"
                src="../../../../assets/images/avatar_company.svg"
              />
              <img
                *ngIf="!contact.isCompany"
                id="pictureContact"
                class="picture-control active"
                src="../../../../assets/images/user.svg"
              />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!contact.isCompany && contact.parentId">
    <div class="col">
      <div class="form-group">
        <span class="form-control-plaintext form-control-sm">
          <a [routerLink]="'/Contacts/Contacts/Details/' + contact.parentId">{{
            contact.parentName
          }}</a>
        </span>
      </div>
    </div>
  </div>

  <div class="row invisible">
    <div class="col">
      <div class="form-group">Empty</div>
    </div>
  </div>

  <div class="row">
    <div class="col-6 col-lg-6">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_ADDRESS</label
        >
        <div class="col">
          <div class="form-control-plaintext form-control-sm mb-2 h-100 p-0">
            <app-address
              [address]="contact.address"
              [address2]="contact.address2"
              [zipCode]="contact.zipCode"
              [city]="contact.city"
              [country]="contact.countryName"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_PHONE_NUMBER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <app-phone-number
              [phoneNumber]="contact.phoneNumber"
              [isLinkEnabled]="true"
            ></app-phone-number>
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_MOBILE_NUMBER</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <app-phone-number
              [phoneNumber]="contact.mobilePhoneNumber"
              [isLinkEnabled]="true"
            ></app-phone-number>
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_EMAIL</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            <app-mail-to-link [email]="contact.email" />
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_TITLE</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ contact.titleName === "" ? contact.title : contact.titleName }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate
          >CONTACT_LBL_PROFESSION</label
        >
        <div class="col">
          <span class="form-control-plaintext form-control-sm">
            {{ contact.profession }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <app-contact-tabs-details />
</ng-container>
