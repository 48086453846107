import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  DragStoppedEvent,
  GridApi,
  GridOptions,
  GridSizeChangedEvent,
  RowDragEnterEvent,
  RowDropZoneParams,
} from 'ag-grid-community';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonTranslateService } from '../../../common/translate/translate.service';
import { filterTrue } from '../../pipe/rxjs/operators';
import { DragAndDropService } from '../../service/drag-and-drop/drag-and-drop.service';
import { GridService } from '../../service/grid.service';
import { NotificationService } from '../../store/notification/notification.service';
import {
  NotificationStatus,
  NotificationType,
} from '../../store/notification/types';
import {
  ActionType,
  Column,
  NavigationObject,
  View,
} from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-generic-grid',
  templateUrl: './generic-grid.component.html',
  styleUrls: ['./generic-grid.component.scss'],
})
export class GenericGridComponent implements OnInit, OnDestroy {
  @Input({ required: true }) data: any[] = [];
  @Input({ required: true }) tabViewName: string;
  @Input() pageSize: number = 20;
  @Input() rowHeight: number;
  @Input() dragAndDrop: boolean = false;

  view$: Observable<View>;
  columnDefs: ColDef[];
  gridOptions: GridOptions<any> = {};
  noRowsTemplate: string;
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  isVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  context: any;
  onActionClickedSetRowData = output<any>();

  registeredDropZones: RowDropZoneParams[] = [];
  registeredContainers: HTMLElement[] = [];

  constructor(
    private readonly router: Router,
    private readonly viewService: ViewService,
    private readonly gridService: GridService,
    private readonly translate: CommonTranslateService,
    private readonly dragAndDropService: DragAndDropService,
    private readonly notificationService: NotificationService
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    this.noRowsTemplate = this.translate.instant('AG_GRID_NO_ROWS_TO_SHOW');

    //get default grid options from service
    this.gridOptions = this.gridService.getConfig();
    //if drag and drop, customize the text
    this.gridOptions.rowDragText = (params, dragItemCount) => {
      return params.rowNode?.data['name']?.replace(/['"]/g, ' ');
    };

    this.view$.pipe(filterTrue(), untilDestroyed(this)).subscribe((view) => {
      if (!view.tabViews) {
        this.isVisible$.next(false);
        return;
      }
      const tabViews = view.tabViews.filter((x) => x.name === this.tabViewName);

      if (!tabViews || tabViews.length == 0) {
        this.isVisible$.next(false);
        return;
      }

      this.isVisible$.next(true);

      const tabView = tabViews[0];

      //Setup tab grid columns
      this.columnDefs = [];

      //Empty Columns for drag and drop //no action
      if (this.dragAndDrop) {
        this.columnDefs.push(this.gridService.getEmptyColumnDef());
      }
      tabView.treeView.columns.forEach((column) => {
        this.columnDefs.push(this.getColumnDef(column));
      });
      this.columnDefs[0].rowDrag = this.dragAndDrop;

      if (tabView.treeView.defaultSort) {
        let indexDefaultSort = this.columnDefs.findIndex(
          (column) => column.field === tabView.treeView.defaultSort
        );
        if (indexDefaultSort >= 0) {
          switch (tabView.treeView.orderBy) {
            case 'asc':
              this.columnDefs[indexDefaultSort].sort = 'asc';
              break;
            case 'desc':
              this.columnDefs[indexDefaultSort].sort = 'desc';
              break;
            default:
              this.columnDefs[indexDefaultSort].sort = null;
              break;
          }
        }
      }
      this.gridOptions.columnDefs = this.columnDefs;

      //Set grid actions
      let navigationObject: NavigationObject = {
        left: undefined,
        middle: undefined,
      };
      tabView.treeView.actions.forEach((action) => {
        if (action.mode === 'Navigate') {
          let type: ActionType;
          let url: string;
          let idProperty: string = action.idProperty;
          switch (action.actionName) {
            case 'openDetail':
              type = 'openDetail';
              url = [
                this.router.url.substring(0, this.router.url.lastIndexOf('/')),
                'Details',
              ].join('/');
              break;
            case 'openBlankDetail':
              type = 'openBlankDetail';
              url = [
                this.router.url.substring(0, this.router.url.lastIndexOf('/')),
                'Details',
              ].join('/');
              break;
            case 'openExternalDetail':
              type = 'openExternal';
              url = action.route;
              break;
            case 'openExternalBlankDetail':
              type = 'openExternalBlank';
              url = action.route;
              break;
            default:
              type = '';
              url = '';
              idProperty = '';
              break;
          }

          if (action.name === 'leftClick') {
            navigationObject.left = {
              type: type,
              url: url,
              idProperty: idProperty,
            };
          }
          if (action.name === 'middleClick') {
            navigationObject.middle = {
              type: type,
              url: url,
              idProperty: idProperty,
            };
          }
        }
        // if (action.mode === 'Action') {
        //   if (action.name === 'rowSelection') {
        //     this.rowSelectionOptions = {
        //       mode: 'multiRow',
        //       isRowSelectable: (rowNode) => true,
        //       headerCheckbox: true,
        //       enableClickSelection: true,
        //       enableSelectionWithoutKeys: true,
        //     };
        //   }
        // }
      });

      if (navigationObject) {
        this.gridOptions.onCellMouseDown = (event) => {
          //'ag-Grid-ControlsColumn' is the selection checkbox from ag-grid
          if (event.colDef.colId !== 'ag-Grid-ControlsColumn') {
            if (event.event && event.event['button'] === 1) {
              //middle click
              const middle = navigationObject.middle;
              if (middle) {
                const url: string =
                  middle.url +
                  (middle.url[middle.url.length - 1] === '/' ? '' : '/') +
                  event.data[middle.idProperty];

                if (
                  middle.type === 'openDetail' ||
                  middle.type === 'openExternal'
                ) {
                  this.router.navigate([url]);
                }
                if (
                  middle.type === 'openBlankDetail' ||
                  middle.type === 'openExternalBlank'
                ) {
                  this.router.navigate([]).then((result) => {
                    window.open(url, '_blank');
                  });
                }
              }
            } else {
              //leftclick
              const left = navigationObject.left;
              if (left) {
                const url: string =
                  left.url +
                  (left.url[left.url.length - 1] === '/' ? '' : '/') +
                  event.data[left.idProperty];

                if (
                  left.type === 'openDetail' ||
                  left.type === 'openExternal'
                ) {
                  this.router.navigate([url]);
                }
                if (
                  left.type === 'openBlankDetail' ||
                  left.type === 'openExternalBlank'
                ) {
                  this.router.navigate([]).then((result) => {
                    window.open(url, '_blank');
                  });
                }
              }
            }
          }
        };
      }

      if (this.agGrid != undefined) {
        // this.gridOptions.columnDefs = this.columnDefs;
        this.agGrid.api.sizeColumnsToFit();

        //Update the grid Options
        this.agGrid.api.updateGridOptions(this.gridOptions);
      }
    });
  }

  ngOnDestroy(): void {}

  onGridReady(params): void {
    this.gridOptions.columnDefs = this.columnDefs;
    params.api.sizeColumnsToFit();
    params.api.updateGridOptions(this.gridOptions);
  }

  onGridSizeChanged($event: GridSizeChangedEvent<any>) {
    this.agGrid.api.sizeColumnsToFit();
  }

  getColumnDef(column: Column): ColDef<any> {
    let colDef: ColDef = {
      field: column.name,
      tooltipField: column.name, //tooltip when truncated
      headerName: column.title,
      headerTooltip: column.title, //tooltip header when truncated
      sortable: column.sortable,
      suppressMovable: true, //column not movable for all
      sort: null,
      sortingOrder: ['asc', 'desc'],
      resizable: false,
      unSortIcon: true, //Icon to show sort is possible
    };

    if (column.width != 0) {
      colDef.maxWidth = column.width;
      colDef.minWidth = column.width;
      colDef.tooltipField = undefined; //remove the tooltip as it will be never truncated
    } else {
      colDef.minWidth = 60; //in width to see a part of the value
    }

    return this.gridService.renderCustomColumn(column, colDef);
  }

  setRowData(rowData: any): void {
    this.onActionClickedSetRowData.emit(rowData);
  }

  initDropZone(params: RowDragEnterEvent) {
    this.removeDropZones(params.api);

    try {
      const tileContainers = !!params.node.data.documentTypeId
        ? (document.querySelectorAll(
            `[id^='tile-container-${params.node.data.documentTypeId}']`
          ) as NodeListOf<HTMLElement>)
        : (document.querySelectorAll(
            `[id^='tile-container-']`
          ) as NodeListOf<HTMLElement>);

      tileContainers.forEach((container) => {
        if (!container) return;

        this.registeredContainers.push(container);

        // container.style.borderColor = '#445066';
        container.classList.remove(...['file-upload-wrapper-drag-enter']);
        container.classList.add('file-upload-wrapper-drop-zone');

        const dropZone: RowDropZoneParams = {
          getContainer: () => container,
          onDragEnter: () => {
            // container.style.borderColor = '#979797';
            container.classList.remove(...['file-upload-wrapper-drop-zone']);
            container.classList.add('file-upload-wrapper-drag-enter');
          },
          onDragLeave: () => {
            // container.style.borderColor = '#cccccc';
            // container.style.borderColor = '#445066';
            container.classList.remove(...['file-upload-wrapper-drag-enter']);
            container.classList.add('file-upload-wrapper-drop-zone');
          },
          onDragStop: (params) => {
            // console.log(params);
            // console.log(container);

            //Get id from the div container id
            const idx = container.id.lastIndexOf('-');

            //TODO : Clean this
            if (idx < -1) {
              this.notificationService.add({
                timestamp: new Date().getTime(),
                content: 'Erreur drag ',
                notificationStatus: NotificationStatus.Error,
                userCancelled: false,
                type: NotificationType.Toast,
                customNotification: false,
              });
            }
            const documentRequestIdStr: string = container.id.substring(
              idx + 1
            );
            const documentRequestId: number =
              Number.parseInt(documentRequestIdStr);
            if (Number.isNaN(documentRequestId)) {
              this.notificationService.add({
                timestamp: new Date().getTime(),
                content: 'Erreur doc id ',
                notificationStatus: NotificationStatus.Error,
                userCancelled: false,
                type: NotificationType.Toast,
                customNotification: false,
              });
            }

            this.dragAndDropService.copyDocumentToDocumentRequest(
              params.node.data.id,
              documentRequestId
            );
          },
        };

        params.api.addRowDropZone(dropZone);
        this.registeredDropZones.push(dropZone);
      });
    } catch (e) {
      console.error(e);
    }
  }

  removeDropZones(api: GridApi<any>) {
    //Remove all registeredDropZones registered
    this.registeredDropZones.forEach((dropZone) => {
      api.removeRowDropZone(dropZone);
    });

    //Clear registeredDropZones registered
    this.registeredDropZones = [];
  }

  onDragStopped(params: DragStoppedEvent) {
    //Remove border color
    this.registeredContainers.forEach((container) => {
      if (!container) return;
      container.classList.remove(
        ...['file-upload-wrapper-drag-enter', 'file-upload-wrapper-drop-zone']
      );
      // container.style.borderColor = '#cccccc';
    });
  }

  onRowDragEnter(params: RowDragEnterEvent<any>) {
    this.initDropZone(params);
  }
}
