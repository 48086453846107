import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import {
  onApiCall,
  onArrayApiCall,
} from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { User, UserConnectionHistory } from './type';
import { userActions } from './user.actions';

export interface IUserState extends IState<User> {
  get: GenericState<User[]>;
  getById: GenericState<User>;
  add: GenericState<User>;
  update: GenericState<User>;
  lockout: GenericState<boolean>;
  getPaged: GenericState<any>;
  getCurrentUser: GenericState<User>;
  getConnectionHistoriesById: GenericState<UserConnectionHistory[]>;
  getMainSalesTeamById: GenericState<number>;
}

export const userInitialState: IUserState = {
  get: new GenericState<User[]>(),
  getById: new GenericState<User>(),
  add: new GenericState<User>(),
  update: new GenericState<User>(),
  lockout: new GenericState<boolean>(),
  getPaged: new GenericState<any>(),
  getCurrentUser: new GenericState<User>(),
  getConnectionHistoriesById: new GenericState<UserConnectionHistory[]>(),
  getMainSalesTeamById: new GenericState<number>(),
};

const userReducers = [
  ...onArrayApiCall<User>(userActions.get, 'get'),
  ...onApiCall<User>(userActions.getById, 'getById'),
  ...onApiCall<User>(userActions.add, 'add'),
  ...onApiCall<User>(userActions.update, 'update'),
  ...onApiCall<boolean>(userActions.lockout, 'lockout'),
  ...onApiCall<PageResult<User>>(userActions.getPaged, 'getPaged'),
  ...onApiCall<User>(userActions.getCurrentUser, 'getCurrentUser'),
  ...onApiCall<UserConnectionHistory[]>(
    userActions.getConnectionHistoriesById,
    'getConnectionHistoriesById',
  ),
  ...onApiCall<number | undefined>(
    userActions.getMainSalesTeamById,
    'getMainSalesTeamById',
  ),
];

export const userReducer = createReducer(
  userInitialState,
  ...userReducers,
) as ActionReducer<IUserState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return userReducer(state, action);
}
