// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'debug',
  release: {
    number: new Date().getTime(),
  },
  comparanooapiurl: '#{ComparanooApiUrl}',
  comparanoourl: '#{ComparanooUrl}',
  layout: {
    navigation: {
      url: '/api/views/modules',
    },
    header: {
      url: '/api/headermenu',
    },
    menu: {
      url: '/api/app',
    },
  },
  features: {
    leads: {
      url: '/api/leads',
      post: 'externalsystem',
    },
    contracts: {
      url: '/api/contracts',
    },
    bankaccounts: {
      url: '/api/bankaccounts',
    },
    paymentsplittings: {
      url: '/api/paymentsplitting',
    },
    zipcodes: {
      url: '/api/zipcodes',
    },
    counties: {
      url: '/api/counties',
    },
    users: {
      url: '/api/users',
      addurl: 'register',
      details: '/byId',
      currentUser: '/currentUser',
      lockout: {
        url: '/api/useraccounts/activate',
      },
    },
    authentification: {
      url: '/connect/token',
    },
    content: {
      url: '/api/pages',
      articles: 'articles',
      glossary: 'definitions',
    },
    search: {
      url: '/api/search',
    },
    core: {
      url: '/api/core',
    },
    reporting: {
      url: '/api/reporting',
    },
    currencies: {
      url: '/api/currencies',
    },
    countries: {
      url: '/api/countries',
    },
    jobs: {
      url: '/api/jobs',
    },
    password: {
      url: 'api/password',
      forgot: '/forgotpassword',
      reset: '/resetpassword',
      change: '/changepassword',
    },
    views: {
      url: '/api/views',
    },
    comment: {
      url: '/api/comments',
    },
    thumbnail: {
      url: '/api/thumbnail',
      avatar: '/avatar',
    },
    salesteam: {
      url: '/api/salesteams',
    },
    salesteammember: {
      url: '/api/salesteammembers',
    },
    campaign: {
      url: '/api/campaigns',
    },
    employee: {
      url: '/api/employees',
    },
    contact: {
      url: '/api/contacts',
    },
    product: {
      url: '/api/products',
    },
    document: {
      url: '/api/documents',
    },
    documentType: {
      url: '/api/documenttypes',
    },
    productcategory: {
      url: '/api/productcategories',
    },
    productline: {
      url: '/api/productlines',
    },
    insuranceCompany: {
      url: '/api/insurancecompanies',
    },
    titles: {
      url: '/api/titles',
    },
    banks: {
      url: '/api/banks',
    },
    history: {
      url: '/api/histories',
    },
    insuranceCoverage: {
      url: '/api/insurancecoverages',
    },
    productCoverage: {
      url: '/api/productcoverages',
    },
    activityType: {
      url: '/api/activitytypes',
    },
    activity: {
      url: '/api/activities',
    },
    lostReason: {
      url: '/api/leadlostreasons',
    },
    terminationReason: {
      url: '/api/terminationreasons',
    },
    quote: {
      url: '/api/quotes',
    },
    invoice: {
      url: '/api/invoices',
    },
    payment: {
      url: '/api/payments',
    },
    invoiceline: {
      url: '/api/invoicelines',
    },
    accounting: {
      url: '/api/accountmoves',
    },
    scraper: {
      url: '/api/ocealizscrapers',
    },
    carReferenceBrand: {
      url: '/api/carreferencebrands',
    },
    carReferenceModel: {
      url: '/api/carreferencemodels',
    },
    carReferenceExactModel: {
      url: '/api/carreferenceexactmodels',
    },
    commission: {
      url: '/api/commissions',
    },
    commissionPlanRule: {
      url: '/api/commissionPlanRules',
    },
    applicationFeeRule: {
      url: '/api/applicationFeeRules',
    },
    account: {
      url: '/api/accounts',
    },
    cashControl: {
      url: '/api/cashControls',
    },
    salesObjectivesRule: {
      url: '/api/salesObjectivesRules',
    },
  },
  files: {
    csvTemplate: {
      url: '/Resources/BordereauReglementExemple.csv',
    },
  },
  msal: {},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
