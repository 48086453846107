import { AppService } from './app.service';
import { AppState } from './types';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { StoreRouterModule } from './router/store-router.module';
import { StoreLeadModule } from './lead/store-lead.modules';
import { StoreSubHeaderModule } from './subheader/store-subheader.module';
import { StoreUserModule } from './user/store-user.modules';
import { StoreZipCodeModule } from './zipcode/store-zipcode.module';
import { StoreCountyModule } from './county/store-county.module';
import { StoreContractModule } from './contract/store-contract.module';
import { StorePaginationdModule } from './pagination/store-pagination.module';
import { StoreArticleModule } from './article/store-article.module';
import { StoreGlossaryModule } from './glossary/store-glossary.module';
import { StoreCoreModule } from './core/store-core.module';
import { StoreSearchModule } from './search/store-search-module';
import { StoreAuthenticationModule } from './authentication/store-authentication.module';
import { StoreReportingModule } from './reporting/store-reporting-modules';
import { StoreCurrencyModule } from './currency/store-currency.module';
import { StoreCountryModule } from './country/store-country.module';
import { StoreNotificationModule } from './notification/store-notification.module';
import { StoreJobModule } from './job/store-job.module';
import { StoreBankAccountModule } from './bank-account/store-bank-account.module';
import { StorePaymentSplittingModule } from './payment-splitting/store-payment-splitting.module';
import { StoreViewModule } from './view/store-view.module';
import { StoreCommentModule } from './comment/store-comment.module';
import { StoreSalesTeamModule } from './sales-team/store-sales-team.module';
import { StoreCampaignModule } from './campaign/store-campaign.module';
import { StoreSalesTeamMemberModule } from './sales-team-member/store-sales-team-member.module';
import { StoreEmployeeModule } from './employee/store-employee.module';
import { StoreTypeaheadModule } from './typeahead/store-typeahead.module';
import { StoreFormModule } from './form/store-form.module';
import { StoreContactModule } from './contact/store-contact.module';
import { StoreDocumentModule } from './document/store-document.module';
import { StoreProductModule } from './product/store-product.module';
import { StoreDocumentTypeModule } from './document-type/store-document-type.module';
import { StoreProductCategoryModule } from './product-category/store-product-category.module';
import { StoreInsuranceCompanyModule } from './insurance-company/store-insurance-company.module';
import { StoreProductLineModule } from './product-line/store-product-line.module';
import { StoreTitleModule } from './title/store-title.module';
import { StoreBankModule } from './bank/store-bank.module';
import { StoreUploadFileModule } from './upload-file/store-upload-file.module';
import { StoreHistoryModule } from './history/store-history.module';
import { StoreInsuranceCoverageModule } from './insurance-coverage/store-insurance-coverage.module';
import { StoreProductCoverageModule } from './product-coverage/store-product-coverage.module';
import { StoreNavigationModule } from './navigation/store-navigation.module';
import { StoreActivityModule } from './activity/store-activity.module';
import { StoreActivityTypeModule } from './activity-type/store-activity-type.module';
import { StoreLostReasonModule } from './lost-reason/store-lost-reason.module';
import { StoreTerminationReasonModule } from './termination-reason/store-termination-reason.module';
import { StoreQuoteModule } from './quote/store-quote.module';
import { StorePaymentModule } from './payment/store-payment.module';
import { StoreInvoiceModule } from './invoice/store-invoice.module';
import { StoreInvoiceLineModule } from './invoice-line/store-invoice-line.module';
import { StoreScraperModule } from './scraper/store-scraper.module';
import { StoreAccountingModule } from './accounting/store-accounting.module';
import { StoreCarReferenceBrandModule } from './car-reference-brand/store-car-reference-brand.module';
import { StoreCarReferenceModelModule } from './car-reference-model/store-car-reference-model.module';
import { StoreCarReferenceExactModelModule } from './car-reference-exact-model/store-car-reference-exact-model.module';
import { StoreCommissionModule } from './commissions/commission/store-commission.module';
import { StoreCommissionPlanRuleModule } from './commissions/commission-plan-rule/store-commission-plan-rule.module';
import { StoreApplicationFeeRuleModule } from './application-fee-rule/store-application-fee-rule.module';
import { StoreCashControlModule } from './cash-control/store-cash-control.module';
import { StoreAccountModule } from './account/store-account.module';
import { StoreSalesObjectivesRuleModule } from './sales-objectives-rule/store-sales-objectives-rule.module';

@NgModule({
  imports: [
    StoreModule.forRoot<AppState>({
      router: routerReducer,
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'STORE - @ngrx DevTools',
      logOnly: environment.production,
    }),
    StoreRouterModule,

    StoreLeadModule,

    StoreContractModule,

    StoreSubHeaderModule,

    StoreZipCodeModule,

    StoreCountyModule,

    StoreUserModule,

    StorePaginationdModule,

    StoreArticleModule,

    StoreGlossaryModule,

    StoreCoreModule,

    StoreSearchModule,

    StoreAuthenticationModule,

    StoreReportingModule,

    StoreCurrencyModule,

    StoreCountryModule,

    StoreNotificationModule,

    StoreJobModule,

    StoreBankAccountModule,

    StorePaymentSplittingModule,

    StoreCommentModule,

    StoreViewModule,

    StoreSalesTeamModule,

    StoreSalesTeamMemberModule,

    StoreCampaignModule,

    StoreEmployeeModule,

    StoreTypeaheadModule,

    StoreFormModule,

    StoreContactModule,

    StoreProductModule,

    StoreDocumentModule,

    StoreDocumentTypeModule,

    StoreProductCategoryModule,

    StoreInsuranceCompanyModule,

    StoreProductLineModule,

    StoreTitleModule,

    StoreBankModule,

    StoreUploadFileModule,

    StoreHistoryModule,

    StoreInsuranceCoverageModule,

    StoreProductCoverageModule,

    StoreNavigationModule,

    StoreActivityModule,

    StoreActivityTypeModule,

    StoreLostReasonModule,

    StoreTerminationReasonModule,

    StoreQuoteModule,

    StorePaymentModule,

    StoreInvoiceModule,

    StoreInvoiceLineModule,

    StoreScraperModule,

    StoreAccountingModule,

    StoreCarReferenceBrandModule,

    StoreCarReferenceModelModule,

    StoreCarReferenceExactModelModule,

    StoreCommissionModule,

    StoreCommissionPlanRuleModule,

    StoreApplicationFeeRuleModule,

    StoreCashControlModule,

    StoreAccountModule,

    StoreSalesObjectivesRuleModule,
  ],
  providers: [AppService],
})
export class AppStoreModule {}
