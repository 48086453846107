import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { ContractModel } from 'src/app/shared/store/contract/types';
import { PaymentSplittingService } from '../../../../../../shared/store/payment-splitting/payment-splitting.service';
import { PaymentSplitting } from '../../../../../../shared/store/payment-splitting/type';

@Component({
  selector: 'app-contract-details-payment-splitting',
  templateUrl: './contract-details-payment-splitting.component.html',
  styleUrls: ['./contract-details-payment-splitting.component.scss'],
})
export class ContractDetailsPaymentSplittingComponent implements OnInit {
  contractBase$: Observable<ContractModel>;
  paymentSplitting$: Observable<PaymentSplitting>;

  constructor(
    private readonly paymentSplittingService: PaymentSplittingService,
    private readonly contractService: ContractService
  ) {}

  ngOnInit(): void {
    this.paymentSplitting$ = this.paymentSplittingService.getById.value$;
    this.contractBase$ = this.contractService.getByUniqueId.value$;
  }
}
