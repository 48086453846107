import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  ISalesObjectivesRuleState,
  salesObjectivesRuleInitialState,
  salesObjectivesRuleReducer,
} from './sales-objectives-rule.reducer';
import { salesObjectivesRuleFeatureKey } from './types';
import { SalesObjectivesRuleEffects } from './sales-objectives-rule.effects';
import { SalesObjectivesRuleApiService } from './sales-objectives-rule.api.service';
import { SalesObjectivesRuleService } from './sales-objectives-rule.service';

@NgModule({
  imports: [
    StoreModule.forFeature<ISalesObjectivesRuleState>(
      salesObjectivesRuleFeatureKey,
      salesObjectivesRuleReducer,
      {
        initialState: salesObjectivesRuleInitialState,
      },
    ),
    EffectsModule.forFeature([SalesObjectivesRuleEffects]),
  ],
  providers: [SalesObjectivesRuleApiService, SalesObjectivesRuleService],
})
export class StoreSalesObjectivesRuleModule {}
