import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SalesObjectivesRoutingModule } from './sales-objectives-routing.module';
import { SalesObjectivesRuleDetailsComponent } from './sales-objectives-rule/sales-objectives-rule-details/sales-objectives-rule-details.component';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SalesObjectivesRuleCreateComponent } from './sales-objectives-rule/sales-objectives-rule-create/sales-objectives-rule-create.component';
import { SalesObjectivesRuleEditComponent } from './sales-objectives-rule/sales-objectives-rule-edit/sales-objectives-rule-edit.component';
import { SalesObjectivesRuleTabsDetailsComponent } from './sales-objectives-rule/sales-objectives-rule-tabs-details/sales-objectives-rule-tabs-details.component';
import { SalesObjectivesRuleDetailsSalesObjectivesRulesComponent } from './sales-objectives-rule/sales-objectives-rule-details-sales-objectives-rules/sales-objectives-rule-details-sales-objectives-rules.component';

@NgModule({
  declarations: [
    SalesObjectivesRuleDetailsComponent,
    SalesObjectivesRuleCreateComponent,
    SalesObjectivesRuleEditComponent,
    SalesObjectivesRuleTabsDetailsComponent,
    SalesObjectivesRuleDetailsSalesObjectivesRulesComponent,
  ],
  imports: [
    CommonModule,
    SalesObjectivesRoutingModule,
    SharedModule,
    TranslateModule,
  ],
})
export class SalesObjectivesModule {}
