import { Component, Input, OnInit } from '@angular/core';
import { ButtonType } from 'src/app/shared/store/subheader/type';
import { DocumentService } from '../../../store/document/document.service';
import { Observable } from 'rxjs';
import { DocumentModel } from '../../../store/document/types';
import { InputByEntity } from '../../../store/comment/types';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { ConfigurationService } from 'src/app/shared/service/configuration.service';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { ModalContentComponent } from '../../modal-content/modal-content.component';
import { ActionButton } from '../../modal-content/action-button';
import { toFirstLetterLower } from '../../../helper/stringhelper';
import { SourceEnum } from '../../../store/typeahead/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../service/modal.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-tab-documents',
  templateUrl: './tab-documents.component.html',
  styleUrl: './tab-documents.component.scss',
})
export class TabDocumentsComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;

  documentBase$: Observable<DocumentModel[]>;
  inputDocument: InputByEntity;
  downloadClicked: boolean = false;
  rowData: DocumentModel;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly documentService: DocumentService,
    private readonly subHeaderService: SubHeaderService,
    private readonly fileSaverService: FileSaverService,
    private readonly configurationService: ConfigurationService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly confirmationDialogService: ConfirmationDialogService,
  ) {
    this.documentBase$ = this.documentService.getByEntityId.value$;
  }

  // documentDownload
  ngOnInit(): void {
    this.inputDocument = {
      entityId: +this.entityId,
      entityType: this.entityType,
    };
    this.documentService.getByEntityId.call(this.inputDocument);

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'documentDownload') {
          this.onClickDownload();
        }
        if (x == 'documentDelete') {
          this.onDelete();
        }
        if (x == 'documentEdit') {
          this.onOpenModalEdit();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  refresh(newDocument: boolean) {
    if (newDocument) {
      this.documentService.getByEntityId.call(this.inputDocument);
    }
  }

  onGetRowData() {
    //copy the data
    const result = this.rowData;

    //when we get the data, reset the data
    this.onSetRowData(undefined);

    //return the copy of the data
    return result;
  }

  onSetRowData(rowData: any) {
    this.rowData = rowData;
  }

  private onClickDownload() {
    this.downloadClicked = true;
    const document: DocumentModel = this.onGetRowData();

    const getFileSubscription = this.documentService.getFile.value$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result: Blob) => {
        if (
          this.configurationService
            .getOpenFileExtensions()
            .some((x) => x.includes(document.fileExtension))
        ) {
          var fileURL = window.URL.createObjectURL(result);
          let tab = window.open();
          if (tab) {
            tab.location.href = fileURL;
          }
        } else {
          this.fileSaverService.save(
            result,
            document.name,
            document.contentType,
          );
        }
        this.downloadClicked = false;
        // this.downloadFinished = true;
        this.documentService.getFile.reset();
      });

    this.documentService.getFile.call(document.id.toString());
  }

  private onDelete() {
    const document: DocumentModel = this.onGetRowData();

    // this.confirmationDialogService
    //   .confirm('Confirmation', 'Voulez-vous supprimer ?')
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       let result$ = this.documentService.delete.call(
    //         document.id.toString(),
    //       );
    //
    //       result$.pipe(filterTrue(), take(1)).subscribe((result) => {
    //         this.documentService.delete.reset();
    //       });
    //     }
    //   })
    //   .catch(() => {});

    let result$ = this.documentService.delete.call(document.id.toString());

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.documentService.delete.reset();
    });
  }

  private onOpenModalEdit() {
    if (this.downloadClicked) {
      this.downloadClicked = false;
      return;
    }
    const document: DocumentModel = this.onGetRowData();

    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'DocumentEditContentComponent',
      );
    modalRef.componentInstance.title = 'Document';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: toFirstLetterLower('document') + 'Update',
      },
    ];
    modalRef.componentInstance.inputParams = {
      modalId: document.id,
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          modalRef.close();
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }
}
