import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from 'src/app/shared/components/data-listing-provider/data-listing-provider.component';
import { AuthenticationGuard } from '../../../shared/guard/authentication-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ModuleId } from '../../../shared/store/navigation/types';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { CompanyCommissionComponent } from '../company-commission/company-commission/company-commission.component';
import { SalespersonCommissionPaymentComponent } from './salesperson-commission-payment/salesperson-commission-payment.component';

const routes: Routes = [
  {
    path: 'Finance/SalesPersonCommissions/Previews',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.SalesPersonCommissionPreview,
          breadcrumb: { skip: true },
        },
      },
    ],
  },
  {
    path: 'Finance/SalesPersonCommissions/AccountMoves',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.SalesPersonCommission,
          breadcrumb: { skip: true },
        },
      },
    ],
  },
  {
    path: 'Finance/SalesPersonCommissions/Payments',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: SalespersonCommissionPaymentComponent,
        canActivate: [AuthenticationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Finance,
          moduleId: ModuleId.finance,
          entity: Entity.SalesPersonCommissionPayment,
          breadcrumb: { skip: true },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommissionRoutingModule {}
