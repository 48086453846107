<div class="d-flex flex-column align-items-stretch px-3">
  <div
    class="filter-infos d-flex justify-content-between align-items-center mb-1"
  >
    <small *ngIf="this.searchModuleIndex == 0; else orBlock" translate
      >SEARCH_LBL_CUSTOM_FILTER</small
    >
    <button class="btn btn-outline-dark btn-sm" (click)="removeCustomFilter()">
      <i class="mdi mdi-trash-can-outline"></i>
    </button>
  </div>

  <select
    id="propertyId"
    class="form-control form-control-sm mb-2"
    [(ngModel)]="this.domain.field"
    (change)="onFieldSelect()"
    *ngIf="this.view$ | async as view"
  >
    <option *ngFor="let field of view.fields" [ngValue]="field.name">
      {{ field.label }}
    </option>
  </select>
  <select
    id="operationId"
    class="form-control form-control-sm mb-2"
    [(ngModel)]="this.domain.operator"
    *ngIf="this.operations"
  >
    <option *ngFor="let operator of this.operations" [ngValue]="operator">
      {{ operator.label }}
    </option>
  </select>
  <div [ngSwitch]="this.domain.operator.numberOfValues">
    <div *ngSwitchCase="0"></div>
    <div *ngSwitchCase="1">
      <input
        *ngIf="this.currentTypeGroup == 'Text'"
        type="text"
        class="form-control form-control-sm"
        [(ngModel)]="this.domain.value"
        (ngModelChange)="onNgModelChange($event)"
      />
      <input
        *ngIf="this.currentTypeGroup == 'Date'"
        type="date"
        class="form-control form-control-sm"
        [(ngModel)]="this.domain.value"
        (ngModelChange)="onNgModelChange($event)"
      />
    </div>
    <div *ngSwitchCase="2">
      <div class="double-input-group d-flex">
        <input
          *ngIf="this.currentTypeGroup == 'Text'"
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="this.domain.value"
          (ngModelChange)="onNgModelChange($event)"
        />
        <input
          *ngIf="this.currentTypeGroup == 'Date'"
          type="date"
          class="form-control form-control-sm"
          [(ngModel)]="this.domain.value"
          (ngModelChange)="onNgModelChange($event)"
        />
        <span class="mx-2 align-self-end" translate>GLOBAL_MSG_AND</span>
        <input
          *ngIf="this.currentTypeGroup == 'Text'"
          type="text"
          class="form-control form-control-sm"
          [(ngModel)]="this.domain.value2"
          (ngModelChange)="onNgModelChange2($event)"
        />
        <input
          *ngIf="this.currentTypeGroup == 'Date'"
          type="date"
          class="form-control form-control-sm"
          [(ngModel)]="this.domain.value2"
          (ngModelChange)="onNgModelChange2($event)"
        />
      </div>
    </div>
  </div>
</div>

<ng-template #orBlock>Or</ng-template>
