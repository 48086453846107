import { GenericState, IState } from '../../../common/store/types';
import { SalesTeamModel } from './types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { salesTeamActions } from './sales-team.action';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { SalesTeamMember } from '../sales-team-member/types';
import { ListItem } from '../typeahead/types';

export interface ISalesTeamState extends IState<SalesTeamModel> {
  getById: GenericState<SalesTeamModel>;
  getList: GenericState<ListItem[]>;
  getCompanyCommissionList: GenericState<ListItem[]>;
  getSalesTeamMemberList: GenericState<SalesTeamMember[]>;
  getCompanyCommissionSalesTeamMemberList: GenericState<SalesTeamMember[]>;
  create: GenericState<SalesTeamModel>;
  postSalesTeam: GenericState<SalesTeamModel>;
  updateSalesTeam: GenericState<SalesTeamModel>;
}

export const salesTeamInitialState: ISalesTeamState = {
  getById: new GenericState<SalesTeamModel>(),
  getList: new GenericState<ListItem[]>(),
  getCompanyCommissionList: new GenericState<ListItem[]>(),
  getSalesTeamMemberList: new GenericState<SalesTeamMember[]>(),
  getCompanyCommissionSalesTeamMemberList: new GenericState<
    SalesTeamMember[]
  >(),
  create: new GenericState<SalesTeamModel>(),
  postSalesTeam: new GenericState<SalesTeamModel>(),
  updateSalesTeam: new GenericState<SalesTeamModel>(),
};

const salesTeamReducers = [
  ...onApiCall<SalesTeamModel>(salesTeamActions.getById, 'getById'),
  ...onApiCall<ListItem[]>(salesTeamActions.getList, 'getList'),
  ...onApiCall<ListItem[]>(
    salesTeamActions.getCompanyCommissionList,
    'getCompanyCommissionList'
  ),
  ...onApiCall<SalesTeamMember[]>(
    salesTeamActions.getSalesTeamMemberList,
    'getSalesTeamMemberList'
  ),
  ...onApiCall<SalesTeamMember[]>(
    salesTeamActions.getCompanyCommissionSalesTeamMemberList,
    'getCompanyCommissionSalesTeamMemberList'
  ),
  ...onApiCall<SalesTeamModel>(salesTeamActions.create, 'create'),
  ...onApiCall<SalesTeamModel>(salesTeamActions.postSalesTeam, 'postSalesTeam'),
  ...onApiCall<SalesTeamModel>(
    salesTeamActions.updateSalesTeam,
    'updateSalesTeam'
  ),
];

export const salesTeamReducer = createReducer(
  salesTeamInitialState,
  ...salesTeamReducers
) as ActionReducer<ISalesTeamState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return salesTeamReducer(state, action);
}
