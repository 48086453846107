import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { SalesObjectivesRuleModel } from './types';
import { ApplicationFeeType } from '../application-fee-rule/types';

@Injectable({
  providedIn: 'root',
})
export class SalesObjectivesRuleApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<SalesObjectivesRuleModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<SalesObjectivesRuleModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getByUserId = (userId?: number): Observable<SalesObjectivesRuleModel[]> => {
    let parameters: string[];
    parameters = ['user'];

    let params = new HttpParams();
    if (userId) {
      params = params.append('userId', userId.toString());
    }

    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<SalesObjectivesRuleModel[]>(url, { params: params })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  createInstance = ({
    salesObjectivesUserId,
    startDate,
  }: {
    salesObjectivesUserId: number | undefined;
    startDate: Date | undefined;
  }): Observable<SalesObjectivesRuleModel> => {
    let parameters: string[];

    let params = new HttpParams();
    if (salesObjectivesUserId) {
      params = params.append(
        'salesObjectivesRuleUserId',
        salesObjectivesUserId,
      );
    }
    if (startDate) {
      params = params.append('startDate', new Date(startDate).toDateString());
    }
    parameters = ['create'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .get<SalesObjectivesRuleModel>(url, { params: params })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  add = (
    salesObjectivesRuleModel: SalesObjectivesRuleModel,
  ): Observable<SalesObjectivesRuleModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<SalesObjectivesRuleModel>(url, salesObjectivesRuleModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  update = (
    salesObjectivesRuleModel: SalesObjectivesRuleModel,
  ): Observable<SalesObjectivesRuleModel> => {
    let parameters: string[];
    parameters = [salesObjectivesRuleModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<SalesObjectivesRuleModel>(url, salesObjectivesRuleModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  delete = (id: number): Observable<boolean> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .delete<boolean>(url, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.delete),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.salesObjectivesRule.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
