import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BaseServiceFacade,
  GenericApiCall,
} from '../../../../common/store/types';
import { AppState } from '../../types';
import { commissionActions } from './commission.actions';
import { commissionSelectors } from './commission.selector';
import {
  CommissionModel,
  CommissionPreviewModel,
  CommissionReportModel,
  SalesPersonCommissionModel,
} from './types';
import { AccountMoveModel } from '../../accounting/types';

@Injectable()
export class CommissionService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<CommissionModel, string> = this.genericApiCall(
    commissionActions.getById,
    commissionSelectors.getById,
    true
  );
  getCommissionsPreview: GenericApiCall<CommissionPreviewModel[], string> =
    this.genericApiCall(
      commissionActions.getCommissionsPreview,
      commissionSelectors.getCommissionsPreview,
      true
    );
  add: GenericApiCall<
    CommissionPreviewModel[],
    {
      commissionPreviewModels: CommissionPreviewModel[];
      isAllSelected: boolean;
    }
  > = this.genericApiCall(commissionActions.add, commissionSelectors.add, true);

  addSalesPersonCommissionPayment: GenericApiCall<
    AccountMoveModel,
    {
      salesPersonCommissionModels: SalesPersonCommissionModel[];
      isAllSelected: boolean;
    }
  > = this.genericApiCall(
    commissionActions.addSalesPersonCommissionPayment,
    commissionSelectors.addSalesPersonCommissionPayment,
    true
  );

  getCompanyCommissions: GenericApiCall<CommissionReportModel[], string> =
    this.genericApiCall(
      commissionActions.getCompanyCommissions,
      commissionSelectors.getCompanyCommissions,
      true
    );
  getApplicationFeesReport: GenericApiCall<CommissionReportModel[], string> =
    this.genericApiCall(
      commissionActions.getApplicationFeesReport,
      commissionSelectors.getApplicationFeesReport,
      true
    );
}
