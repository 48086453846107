import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../../common/store/types';
import {
  CommissionModel,
  CommissionPreviewModel,
  CommissionReportModel,
} from './types';
import { commissionActions } from './commission.actions';
import { AccountMoveModel } from '../../accounting/types';

export interface ICommissionState extends IState<CommissionModel> {
  getById: GenericState<CommissionModel>;
  getCommissionsPreview: GenericState<CommissionPreviewModel[]>;
  add: GenericState<CommissionPreviewModel[]>;
  addSalesPersonCommissionPayment: GenericState<AccountMoveModel>;
  getCompanyCommissions: GenericState<CommissionReportModel[]>;
  getApplicationFeesReport: GenericState<CommissionReportModel[]>;
}

export const commissionInitialState: ICommissionState = {
  getById: new GenericState<CommissionModel>(),
  getCommissionsPreview: new GenericState<CommissionPreviewModel[]>(),
  add: new GenericState<CommissionPreviewModel[]>(),
  addSalesPersonCommissionPayment: new GenericState<AccountMoveModel>(),
  getCompanyCommissions: new GenericState<CommissionReportModel[]>(),
  getApplicationFeesReport: new GenericState<CommissionReportModel[]>(),
};

const commissionReducers = [
  ...onApiCall<CommissionModel>(commissionActions.getById, 'getById'),
  ...onApiCall<CommissionPreviewModel[]>(
    commissionActions.getCommissionsPreview,
    'getCommissionsPreview'
  ),
  ...onApiCall<CommissionPreviewModel[]>(commissionActions.add, 'add'),
  ...onApiCall<AccountMoveModel>(
    commissionActions.addSalesPersonCommissionPayment,
    'addSalesPersonCommissionPayment'
  ),
  ...onApiCall<CommissionReportModel[]>(
    commissionActions.getCompanyCommissions,
    'getCompanyCommissions'
  ),
  ...onApiCall<CommissionReportModel[]>(
    commissionActions.getApplicationFeesReport,
    'getApplicationFeesReport'
  ),
];

export const commissionReducer = createReducer(
  commissionInitialState,
  ...commissionReducers
) as ActionReducer<ICommissionState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return commissionReducer(state, action);
}
