import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { SalesObjectivesRuleModel } from './types';
import { salesObjectivesRuleActions } from './sales-objectives-rule.actions';

export interface ISalesObjectivesRuleState
  extends IState<SalesObjectivesRuleModel> {
  getById: GenericState<SalesObjectivesRuleModel>;
  getByUserId: GenericState<SalesObjectivesRuleModel[]>;
  createInstance: GenericState<SalesObjectivesRuleModel>;
  add: GenericState<SalesObjectivesRuleModel>;
  update: GenericState<SalesObjectivesRuleModel>;
  delete: GenericState<boolean>;
}

export const salesObjectivesRuleInitialState: ISalesObjectivesRuleState = {
  getById: new GenericState<SalesObjectivesRuleModel>(),
  getByUserId: new GenericState<SalesObjectivesRuleModel[]>(),
  createInstance: new GenericState<SalesObjectivesRuleModel>(),
  add: new GenericState<SalesObjectivesRuleModel>(),
  update: new GenericState<SalesObjectivesRuleModel>(),
  delete: new GenericState<boolean>(),
};

const salesObjectivesRuleReducers = [
  ...onApiCall<SalesObjectivesRuleModel>(
    salesObjectivesRuleActions.getById,
    'getById',
  ),
  ...onApiCall<SalesObjectivesRuleModel[]>(
    salesObjectivesRuleActions.getByUserId,
    'getByUserId',
  ),
  ...onApiCall<SalesObjectivesRuleModel>(
    salesObjectivesRuleActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<SalesObjectivesRuleModel>(salesObjectivesRuleActions.add, 'add'),
  ...onApiCall<SalesObjectivesRuleModel>(
    salesObjectivesRuleActions.update,
    'update',
  ),
  ...onApiCall<boolean>(salesObjectivesRuleActions.delete, 'delete'),
];

export const salesObjectivesRuleReducer = createReducer(
  salesObjectivesRuleInitialState,
  ...salesObjectivesRuleReducers,
) as ActionReducer<ISalesObjectivesRuleState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return salesObjectivesRuleReducer(state, action);
}
