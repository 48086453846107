import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, Observable, take } from 'rxjs';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { ContractModel } from 'src/app/shared/store/contract/types';
import { Entity } from 'src/app/shared/store/view/types';
import { CommissionPlanRuleService } from '../../../../../../shared/store/commissions/commission-plan-rule/commission-plan-rule.service';
import {
  AmountType,
  CommissionPlanRuleModel,
} from '../../../../../../shared/store/commissions/commission-plan-rule/types';
import { getAmountSign } from '../../../../../../shared/helper/amounthelper';

@UntilDestroy()
@Component({
  selector: 'app-contract-form-commission',
  templateUrl: './contract-form-commission.component.html',
  styleUrls: ['./contract-form-commission.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormCommissionComponent implements OnInit {
  form: FormGroup<any>;

  contract$: Observable<ContractModel>;
  commissionPlanRuleByShared$: Observable<CommissionPlanRuleModel>;

  isRateVisible: boolean;
  existingSharedCommissionRate: number;
  existingSharedSalesPersonId: number;
  lastSelectedSharedSalesPersonId: number;

  defaultAmountType: AmountType = 'Percentage';
  amountPlaceholder: string;
  amountSuffix: string;

  isReadOnly$: Observable<boolean>;

  protected readonly Entity = Entity;

  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    private readonly contractService: ContractService,
    private readonly commissionPlanRuleService: CommissionPlanRuleService,
  ) {
    this.commissionPlanRuleByShared$ =
      this.commissionPlanRuleService.getSharedBySalesPersonIdAndCommissionType.value$;
    this.isReadOnly$ =
      this.contractService.getIfDataReadOnlyFromContractStatus.value$;
  }

  ngOnInit(): void {
    this.contract$ = this.contractService.getByUniqueId.value$;

    this.isRateVisible = false;
    this.form = this.parent.form;
    this.form.addControl(
      'commissionFormGroup',
      this.formBuilder.group({
        sharedSalesPersonId: [],
        sharedCommissionRate: [0],
      }),
    );

    this.contract$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.existingSharedCommissionRate = x.sharedCommissionRate;
        this.existingSharedSalesPersonId =
          this.lastSelectedSharedSalesPersonId = x.sharedSalesPersonId;
        this.form.patchValue({
          commissionFormGroup: {
            sharedSalesPersonId: x.sharedSalesPersonId,
            sharedCommissionRate: x.sharedCommissionRate,
          },
        });
      });

    this.commissionPlanRuleByShared$
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x) {
          this.form.patchValue({
            commissionFormGroup: {
              sharedCommissionRate: x.value,
            },
          });
          this.amountPlaceholder = this.amountSuffix = getAmountSign(
            this.defaultAmountType,
            x.amountType,
          );
        } else {
          this.form.patchValue({
            commissionFormGroup: {
              sharedCommissionRate: this.existingSharedCommissionRate,
            },
          });
          this.amountPlaceholder = this.amountSuffix = getAmountSign(
            this.defaultAmountType,
          );
        }
      });

    this.form.controls['commissionFormGroup']
      .get('sharedSalesPersonId')
      ?.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        if (value == null) {
          this.lastSelectedSharedSalesPersonId = -1;
          this.isRateVisible = false;
          this.commissionPlanRuleService.getSharedBySalesPersonIdAndCommissionType.reset();
          this.form.patchValue({
            commissionFormGroup: {
              sharedCommissionRate: 0,
            },
          });
        } else {
          this.isRateVisible = true;
          if (this.lastSelectedSharedSalesPersonId != +value) {
            this.commissionPlanRuleService.getSharedBySalesPersonIdAndCommissionType.call(
              value.toString(),
            );
          }
          this.lastSelectedSharedSalesPersonId = +value;
        }
      });
  }
}
