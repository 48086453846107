import { createApiCallActions } from 'src/app/common/store/generic.actions';
import {
  CarInsuranceContractModel,
  ContractModel,
  ContractStatusFlow,
  CreateContractFormModel,
  HealthInsuranceContractDetailModel,
  HouseInsuranceContractModel,
} from './types';
import { Action } from '../view/types';

export const contractActions = {
  getById: createApiCallActions<ContractModel>('contract', 'getById'),
  getByUniqueId: createApiCallActions<ContractModel>(
    'contract',
    'getByUniqueId',
  ),
  getByLeadId: createApiCallActions<ContractModel>('contract', 'getByLeadId'),
  getHealthDetail: createApiCallActions<HealthInsuranceContractDetailModel[]>(
    'contract',
    'getHealthDetail',
  ),
  getCarDetail: createApiCallActions<CarInsuranceContractModel>(
    'contract',
    'getCarDetail',
  ),
  getHouseDetail: createApiCallActions<HouseInsuranceContractModel>(
    'contract',
    'getHouseDetail',
  ),
  getStatusFlow: createApiCallActions<ContractStatusFlow[]>(
    'contract',
    'getStatusFlow',
  ),
  getStatusActions: createApiCallActions<Action[]>(
    'contract',
    'getStatusActions',
  ),
  confirm: createApiCallActions<ContractModel>('contract', 'confirm'),
  saveAsDraft: createApiCallActions<ContractModel>('contract', 'saveAsDraft'),
  requestValidation: createApiCallActions<ContractModel>(
    'contract',
    'requestValidation',
  ),
  validate: createApiCallActions<ContractModel>('contract', 'validate'),
  reject: createApiCallActions<ContractModel>('contract', 'reject'),
  markAsRegularized: createApiCallActions<ContractModel>(
    'contract',
    'markAsRegularized',
  ),
  verify: createApiCallActions<ContractModel>('contract', 'verify'),
  suspendContract: createApiCallActions<ContractModel>(
    'contract',
    'suspendContract',
  ),
  closeContract: createApiCallActions<ContractModel>(
    'contract',
    'closeContract',
  ),
  getContractModel: createApiCallActions<ContractModel>(
    'contract',
    'getContractModel',
  ),
  getHealthContractModel: createApiCallActions<
    HealthInsuranceContractDetailModel[]
  >('contract', 'getHealthContractModel'),
  getCarContractModel: createApiCallActions<CarInsuranceContractModel>(
    'contract',
    'getCarContractModel',
  ),
  getHouseContractModel: createApiCallActions<HouseInsuranceContractModel>(
    'contract',
    'getHouseContractModel',
  ),
  createHealhContractModel: createApiCallActions<
    HealthInsuranceContractDetailModel[]
  >('contract', 'createHealhContractModel'),
  createCarContractModel: createApiCallActions<CarInsuranceContractModel>(
    'contract',
    'createCarContractModel',
  ),
  createHouseContractModel: createApiCallActions<HouseInsuranceContractModel>(
    'contract',
    'createHouseContractModel',
  ),
  createContract: createApiCallActions<ContractModel>(
    'contract',
    'createContract',
  ),
  createHealthContract: createApiCallActions<ContractModel>(
    'contract',
    'createHealthContract',
  ),
  createCarContract: createApiCallActions<ContractModel>(
    'contract',
    'createCarContract',
  ),
  createHouseContract: createApiCallActions<ContractModel>(
    'contract',
    'createHouseContract',
  ),
  updateContract: createApiCallActions<ContractModel>(
    'contract',
    'updateContract',
  ),
  updateHealthContract: createApiCallActions<ContractModel>(
    'contract',
    'updateHealthContract',
  ),
  updateCarContract: createApiCallActions<ContractModel>(
    'contract',
    'updateCarContract',
  ),
  updateHouseContract: createApiCallActions<ContractModel>(
    'contract',
    'updateHouseContract',
  ),
  getIfDataReadOnlyFromContractStatus: createApiCallActions<boolean>(
    'contract',
    'getIfDataReadOnlyFromContractStatus',
  ),
};

export type ContractAction = typeof contractActions;
