import { Component } from '@angular/core';

@Component({
  selector: 'app-sales-objectives-rule-create',
  templateUrl: './sales-objectives-rule-create.component.html',
  styleUrl: './sales-objectives-rule-create.component.scss'
})
export class SalesObjectivesRuleCreateComponent {

}
