import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../../common/store/generic.selectors';
import {
  CommissionModel,
  SalesPersonCommissionModel,
  commissionFeatureKey,
} from './types';
import { ICommissionState } from './commission.reducer';

const commissionFeatureState =
  createFeatureSelector<ICommissionState>(commissionFeatureKey);

export const commissionSelectors = {
  getById: createApiCallSelectors<number, ICommissionState>(
    commissionFeatureState,
    'getById'
  ),
  getCommissionsPreview: createApiCallSelectors<void, ICommissionState>(
    commissionFeatureState,
    'getCommissionsPreview'
  ),
  add: createApiCallSelectors<void, ICommissionState>(
    commissionFeatureState,
    'add'
  ),
  addSalesPersonCommissionPayment: createApiCallSelectors<
    SalesPersonCommissionModel[],
    ICommissionState
  >(commissionFeatureState, 'addSalesPersonCommissionPayment'),

  getCompanyCommissions: createApiCallSelectors<void, ICommissionState>(
    commissionFeatureState,
    'getCompanyCommissions'
  ),
  getApplicationFeesReport: createApiCallSelectors<void, ICommissionState>(
    commissionFeatureState,
    'getApplicationFeesReport'
  ),
};
