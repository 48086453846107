import { Injectable } from '@angular/core';
import { SalesTeamApiService } from './sales-team.api.service';
import { SalesTeamAction, salesTeamActions } from './sales-team.action';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';

@Injectable()
export class SalesTeamEffects {
  constructor(
    private readonly salesTeamApiService: SalesTeamApiService,
    private readonly action$: Actions<SalesTeamAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getById,
    this.salesTeamApiService.getById
  );

  getList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getList,
    this.salesTeamApiService.getList
  );

  getCompanyCommissionList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getCompanyCommissionList,
    this.salesTeamApiService.getCompanyCommissionList
  );

  getSalesTeamMemberList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getSalesTeamMemberList,
    this.salesTeamApiService.getSalesTeamMemberList
  );
  getCompanyCommissionSalesTeamMemberList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getCompanyCommissionSalesTeamMemberList,
    this.salesTeamApiService.getCompanyCommissionSalesTeamMemberList
  );

  create = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.create,
    this.salesTeamApiService.create
  );

  postSalesTeam = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.postSalesTeam,
    this.salesTeamApiService.postSalesTeam
  );

  updateSalesTeam = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.updateSalesTeam,
    this.salesTeamApiService.updateSalesTeam
  );
}
