<form [formGroup]="applicationFeeRuleForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="applicationFeeRuleForm">
  <button type="submit" hidden="hidden"></button>

<!--  <div class="row">-->
<!--    <div class="col-6 col-lg-6">-->
<!--      <div class="form-group">-->
<!--        <label class="form-title-label" for="name" translate>COMMISSION_PLAN_RULE_LBL_NAME</label>-->
<!--        <input-->
<!--          id="name"-->
<!--          class="form-control form-title"-->
<!--          type="text"-->
<!--          formControlName="name"-->
<!--          checkFormFieldValidity-->
<!--          [maxLength]="100"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col"></div>-->
<!--  </div>-->

  <div class="row">

    <div class="col-6 col-lg-6">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="productLineId" translate>APPLICATION_FEE_RULE_LBL_PRODUCTLINE_NAME</label>
        <div class="col">
          <app-typeahead
            id="productLineId"
            [routeEndpoint]="'ProductLines'"
            [controlForm]="this.applicationFeeRuleForm.get('productLineId')"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [orderBy]="'name asc'"
            [limitToList]="true"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="applicationFeeType" translate>APPLICATION_FEE_RULE_LBL_TYPE</label>
        <div class="col">
          <app-typeahead
            id="applicationFeeType"
            [limitToList]="true"
            [dataType]="DataType.Enum"
            [valueMember]="'value'"
            [displayMember]="'name'"
            [referenceEndpoint]="'Views/enum/values'"
            [referenceField]="'Comparanoo.Core.Enums.Commissions.ApplicationFeeType'"
            [dropdownStyle]="DropdownStyle.DropDown"
            [controlForm]="this.applicationFeeRuleForm.get('applicationFeeType')"
          ></app-typeahead>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="minimumValue" translate>APPLICATION_FEE_RULE_LBL_MINIMUM_VALUE</label>
        <div class="col">
          <input
            id="minimumValue"
            name="minimumValue"
            class="form-control form-control-sm"
            type="text"
            formControlName="minimumValue"
            checkFormFieldValidity
            [placeholder]="this.amountPlaceholder | translate"
            mask="separator.2"
            [suffix]="this.amountSuffix | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
            (change)="onChangeUpdateDefaultValuePlaceholder()"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="maximumValue" translate>APPLICATION_FEE_RULE_LBL_MAXIMUM_VALUE</label>
        <div class="col">
          <input
            id="maximumValue"
            name="maximumValue"
            class="form-control form-control-sm"
            type="text"
            formControlName="maximumValue"
            checkFormFieldValidity
            [placeholder]="this.amountPlaceholder | translate"
            mask="separator.2"
            [suffix]="this.amountSuffix | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
            (change)="onChangeUpdateDefaultValuePlaceholder()"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="defaultValue" translate>APPLICATION_FEE_RULE_LBL_DEFAULT_VALUE</label>
        <div class="col">
          <input
            id="defaultValue"
            name="defaultValue"
            class="form-control form-control-sm"
            type="text"
            formControlName="defaultValue"
            checkFormFieldValidity
            [placeholder]="this.defaultValuePlaceholder | translate"
            mask="separator.2"
            [suffix]="this.amountSuffix | translate"
            [showMaskTyped]="false"
            [decimalMarker]="','"
            [allowNegativeNumbers]="true"
          />
        </div>
      </div>
    </div>

    <div class="col">
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="startDate" translate>APPLICATION_FEE_RULE_LBL_START_DATE</label>
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="startDate"
            type="date"
            formControlName="startDate"
            checkFormFieldValidity
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="endDate" translate>APPLICATION_FEE_RULE_LBL_END_DATE</label>
        <div class="col">
          <input
            class="form-control form-control-sm"
            id="endDate"
            type="date"
            formControlName="endDate"
            checkFormFieldValidity
          />
        </div>
      </div>
    </div>
  </div>
</form>
